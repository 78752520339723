// form
// @mui
import {Box, Button, Card, CardContent, CircularProgress, Container, Grid, Link, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../hooks/useAuth";
import React, {useState} from 'react';
import {stripeProductIds} from "../../../../constants/stripeProductIds";
import {ReactComponent as BrightGameLogo} from "../../../../assets/logo_round.svg";
import UpsellCard from "./upsellCard";

// hooks
// components
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({theme}) => ({
    backgroundColor: '#c8e8e8',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    maxHeight: '95vh',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(7, 7),
}));

const LeftSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(10, 40, 10, 50),
    borderRadius: '15px 0px 0px 15px'

}));

const RightSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: '#1f8e97',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 7, 2, 7),
    borderRadius: '0px 15px 15px 0px',
    alignItems: 'center'
}));

const CheckListItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'left',
    margin: '12px'
}));

export default function UpsellProHost(props) {

    const {subscribeToPlan} = useAuth();
    const [loading, setLoading] = useState(false);



    return (
        <UpsellCard leftContent={
            <Box sx={{pr: 5, pl: 8}}>
            <Typography variant="h3" gutterBottom sx={{paddingBottom: '20px'}}>
                For those with a serious passion for game design
            </Typography>
            <Typography variant="h4" paragraph color={'#0c4760'} fontWeight={'normal'}>
                Get an extra user and enough game design projects to support your passion.
            </Typography>
            <Typography variant="body1" paragraph color={'#399ba3'}>
                In addition <span
                style={{
                    fontWeight: 'bold',
                    color: '#0c4760'
                }}>build libraries of games </span> and
                make
                it
                easy for other hobbyists to see and share all the games you have created.
            </Typography>
            <Typography variant="body1" paragraph color={'#399ba3'}>
                Benefit from <span
                style={{
                    fontWeight: 'bold',
                    color: '#0c4760'
                }}>collaborative design</span> and <span
                style={{fontWeight: 'bold', color: '#0c4760'}}>access the creator marketplace*
                                        </span> to
                discover and share BrightGames!
            </Typography>
            <Typography variant="body2" paragraph color={'black'} sx={{pt: 2}}>
                Purchase a ‘Pro Host’ annual subscription in advance
                today and save <span
                style={{
                    fontWeight: 'bold',
                    color: '#399ba3'
                }}>15% off your first year</span>!
            </Typography>
            <Typography variant="p" paragraph color={'black'}>
                *creator marketplace coming soon!
            </Typography>
        </Box>} rightContent={<Card sx={{
            backgroundColor: '#08445e',
            margin: '30px 15px 30px 15px',
            textAlign: 'center',
            color: 'white',
        }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Pro Host
                </Typography>
                <Typography variant="h2">
                    {/*{'£' + price.unit_amount / 100}*/}
                    £40
                </Typography>
                <Typography variant="p" gutterBottom>
                    per month
                </Typography>
                <Typography variant="h6" gutterBottom sx={{paddingTop: '10px'}}>
                    Ideal for serious hobbyists
                </Typography>
                <div style={{marginLeft: '5px', marginTop: '20px'}}>
                    <CheckListItem>
                        <CheckCircleIcon color="warning"/>
                        <Typography variant="body1" component="span"
                                    sx={{marginLeft: 1}}>
                            All Standard plan features
                        </Typography>
                    </CheckListItem>
                    <CheckListItem>
                        <CheckCircleIcon color="warning"/>
                        <Typography variant="body1" component="span"
                                    sx={{marginLeft: 1}}>
                            30 game design projects
                        </Typography>
                    </CheckListItem>
                    <CheckListItem>
                        <CheckCircleIcon color="warning"/>
                        <Typography variant="body1" component="span"
                                    sx={{marginLeft: 1}}>
                            Access to games market
                        </Typography>
                    </CheckListItem>
                    <CheckListItem>
                        <CheckCircleIcon color="warning"/>
                        <Typography variant="body1" component="span"
                                    sx={{marginLeft: 1}}>
                            Dedicated landing page
                        </Typography>
                    </CheckListItem><
                    CheckListItem>
                    <CheckCircleIcon color="warning"/>
                    <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                        Add your own logo
                    </Typography>
                </CheckListItem><CheckListItem>
                    <CheckCircleIcon color="warning"/>
                    <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                        2 user licenses
                    </Typography>
                </CheckListItem>
                </div>
                <Button
                    onClick={async () => {
                        setLoading(true)
                        await subscribeToPlan(stripeProductIds.proHost, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                    }}
                    variant="contained" sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    marginTop: '20px',
                    marginBottom: '10px'
                }}>{loading ? <CircularProgress/> : 'Buy Now'}</Button>
                <br/>
                <Link variant="subtitle2" underline="always"
                      sx={{color: 'white', marginTop: '10px'}}
                      onClick={async () => {
                          await subscribeToPlan(stripeProductIds.standard, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                      }}>
                    Continue to standard
                </Link>
            </CardContent>
        </Card>}/>
    )

}