import hobbyfun from '../assets/onboarding/hobbyfun.png';
import educator from '../assets/onboarding/educator.png';
import professional from '../assets/onboarding/professional.png';
import learner from '../assets/onboarding/learner.png';

export const accountLicenseTypes = {
    licenseOwner: 'licenseOwner',
    creator: 'creator',
    allAccess: 'allAccess',
    learner: 'learner'
}

export const accountTypes = [
    {
        name: 'Hobby/Fun',
        image: hobbyfun,
        accountSubType: [
            {name: 'Hobbyist', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Game Designer', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Teacher/Educator', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Trainer/Consultant', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'}
        ]
    },
    {
        name: 'Educator',
        image: educator,
        accountSubType: [
            {name: 'Teacher/Educator', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'School/College', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'In-house/Corporate Trainer', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Consultant/Professional Advisor', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'}
        ]
    },
    {
        name: 'Professional',
        image: professional,
        accountSubType: [
            {name: 'Team leader/Manager', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'HR Training Professional', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Teacher/Educator', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Consultant/Professional Advisor', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'}
        ]
    }, {
        name: 'Learner',
        image: learner,
        accountSubType: [
            {name: 'School student', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'College student', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'University Student', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'},
            {name: 'Vocational learning student', image: '', upsellPlanId: 'prod_NXNVKtzVUZ4a28'}
        ]
    }
]