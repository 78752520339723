// form
// @mui
import {Box, Button, Card, CardContent, Container, Link, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../hooks/useAuth";
import React, {useState} from 'react';
import {stripeProductIds} from "../../../../constants/stripeProductIds";
import UpsellCard from "./upsellCard";
// hooks
// components
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({theme}) => ({
    backgroundColor: '#c8e8e8',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    maxHeight: '95vh',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(7, 7),
}));

const LeftSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(10, 40, 10, 50),
    borderRadius: '15px 0px 0px 15px'

}));

const RightSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: '#1f8e97',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 7, 2, 7),
    borderRadius: '0px 15px 15px 0px',
    alignItems: 'center'
}));

const CheckListItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'left',
    margin: '12px'
}));

export default function UpsellPartnerGold(props) {


    const {firestoreUser, createUserPortalLink, subscribeToPlan} = useAuth();
    const [loading, setLoading] = useState(false);

    return (
        <UpsellCard
            leftContent={
                <Box sx={{pr: 6, pl: 6}}>
                    <Typography variant="h4" gutterBottom>
                        The perfect package for
                        managers and team leaders
                    </Typography>
                    <Typography variant="body1" paragraph color={'#0c4760'} fontWeight={'bold'}>
                        Benefit from bespoke design and a
                        commercial distribution licence.
                    </Typography>
                    <Typography variant="body1" paragraph color={'#399ba3'}>
                        Develop interactive game based learning with <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>25
                    colleagues.</span> In addition <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>benefit from discounts
                    for SkillsPlay Studio support.</span>
                    </Typography>
                    <Typography variant="body1" paragraph color={'#399ba3'}>
                        Impress with games incorporating <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>your organisations identity</span> and customised
                        designs. <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>Share the development process</span> with HR,
                        Training and other internal stakeholders!
                    </Typography>
                    <Typography variant="body2" paragraph color={'black'}>
                        Purchase a ‘Partner Gold’ annual subscription in advance
                        today and save <span
                        style={{fontWeight: 'bold', color: '#399ba3'}}>5% off your first year</span>!
                    </Typography>
                </Box>
            }
            rightContent={
                <Card sx={{
                    backgroundColor: '#08445e',
                    margin: '30px 70px 30px 70px',
                    height: '100%',
                    width: '375px',
                    textAlign: 'center',
                    color: 'white'
                }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Partner Gold
                        </Typography>
                        <Typography variant="h2">
                            {/*{'£' + price.unit_amount / 100}*/}
                            £190
                        </Typography>
                        <Typography variant="p" gutterBottom sx={{marginBottom: '20px'}}>
                            per month
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            £2000 setup fee
                        </Typography>
                        <div style={{marginLeft: '35px', marginTop: '20px'}}>
                            <CheckListItem>
                                <CheckCircleIcon color="warning"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    All Partner Silver plan features
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="warning"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    200 game design projects
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="warning"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    Custom asset discounts
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="warning"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    25 user licenses
                                </Typography>
                            </CheckListItem><
                            CheckListItem>
                            <CheckCircleIcon color="warning"/>
                            <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                2400 maximum players
                            </Typography>
                        </CheckListItem><CheckListItem>
                            <CheckCircleIcon color="warning"/>
                            <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                200 maximum cohorts
                            </Typography>
                        </CheckListItem>
                        </div>
                        <Button variant="contained" sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            marginTop: '20px',
                            marginBottom: '10px'
                        }}
                                onClick={async () => {
                                    setLoading(true)
                                    await subscribeToPlan(stripeProductIds.partnerGold, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                                }}
                        >Buy Now</Button>
                        <br/>
                        <Link variant="subtitle2" underline="always" sx={{color: 'white', marginTop: '10px'}}
                              onClick={async () => {
                                  setLoading(true)
                                  await subscribeToPlan(stripeProductIds.partnerSilver, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                              }}>
                            Continue to Partner Silver
                        </Link>
                    </CardContent>
                </Card>
            }
        />
    )
}
