import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { UserAccountTypeProvider } from './contexts/UserAccountTypeContext';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserAccountTypeProvider>
        <ThemeProvider>
          <ThemeColorPresets>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </ThemeColorPresets>
        </ThemeProvider>
      </UserAccountTypeProvider>
    </QueryClientProvider>
  );
}
