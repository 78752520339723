import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import { accountLicenseTypes } from 'src/constants/accountOptions';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import Onboarding from "../pages/auth/register/Onboarding";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const { isAuthenticated, isInitialized, activeSubscription, firestoreUser, isSeatedUser } = useAuth();
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!isInitialized) {
        return <LoadingScreen/>;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        console.log("loading loggin");
        return <Login/>;
    }

    // check if user is at upsell screen, if they have paid for a subscription then skip
    // need to check if subscription is active


    // Hard coding userIsSeated = true because seems to be a bug where isSeatedUser from useAuth is not correct.
    //const userIsSeated = true;

    console.log("is user seated? " + isSeatedUser);
    console.log("licenseType is " + firestoreUser.licenseType)

    // ************ //
    //TODO:
    // On boarding will need restructuring to account for 3 ways we can sign up/3 account types
    // license owner (purchasing a license)
    // license seat (signing up as a creator but to take a seat on someone elses license)
    // signing up as a learner

    //org type and username ect will need to be taken out of <OnBoarding/> so that step becomes just license purchasing.
    // ************ //

    if (isAuthenticated && (activeSubscription === null)){ //if we have an account but have no yet purchased a license
        if (firestoreUser.licenseType === accountLicenseTypes.licenseOwner){ //if we have signed up to be a license owner
            return <Onboarding/> //run on boarding which takes us through organisation type, username and on to license purchasing
        }
    }

    // if (isAuthenticated && (activeSubscription === null) && (firestoreUser.licenseType === accountLicenseTypes.licenseOwner || firestoreUser.licenseType === undefined) && !isSeatedUser) {
    //     console.log("on board");
    //     return <Onboarding/>
    // }


    console.log("done on boarding");

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}



// auth: yes
// sub: no
//on boarded: no
//type: creator
