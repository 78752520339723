import React from 'react';
import styled, { keyframes } from 'styled-components';

const BouncingLoaderAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;

const BouncingLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  z-index: 999999;
`;

const BouncingDot = styled.div`
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: ${BouncingLoaderAnimation} 0.6s infinite alternate;
`;

export function LoadingDots(props) {
  return (
    <BouncingLoader>
      <BouncingDot />
      <BouncingDot style={{ animationDelay: '0.2s' }} />
      <BouncingDot style={{ animationDelay: '0.4s' }} />
    </BouncingLoader>
  );
}
