import {generateUniqueId} from '../../../../../helper/id';

export default class Actions {
    clickLetsGoButton = (state, action) => {
        state.letsGoButtonClicked = true;
    }

    switchActionPrevalenceSpeechBubble = (state, action) => {
        state.actionPrevalenceSpeechBubbleOpened = !state.actionPrevalenceSpeechBubbleOpened;
    }

    addWildcard = (state, action) => {
        const {title} = action.payload;
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.title === title);
        if (index !== -1) {
            const action = {...state.selectedGame.actions.actionsList[index]}
            action.wildcard = true;
            state.selectedGame.actions.actionsList[index] = action;
        }
    }

    deleteWildcard = (state, action) => {
        const {id} = action.payload;
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        if (index !== -1) {
            state.selectedGame.actions.actionsList[index].wildcard = false;
        }
    }

    updateWildcardValue = (state, action) => {
        const {objectKey, value, id} = action.payload;
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        if (index !== -1) {
            state.selectedGame.actions.actionsList[index][objectKey] = value;
        }
    }

    updateClusterValue = (state, action) => {
        const {objectKey, value, clusterId} = action.payload;
        const index = state.selectedGame.actions.clustersList.findIndex(cluster => cluster.id === clusterId);
        if (index !== -1) {
            state.selectedGame.actions.clustersList[index][objectKey] = value;
        }
    }

    createClustersList = (state, action) => {
        const {newClustersList} = action.payload;
        state.selectedGame.actions.clustersList = [...newClustersList]
    }

    updateClustersActionIds = (state, action) => {
        const {actionTitlesList, clusterId} = action.payload;
        console.log(action.payload);
        //    Loop through each action title and find their id from actionsList. Make into list and set to actionsIds
        //    During loop assign the action a cluster, if not in array and has the clusterId remove it.
        const actionIds = []
        let clusterNetEffect = 0;
        state.selectedGame.actions.actionsList.forEach((action) => {
            console.log(actionTitlesList.includes(action.title));
            if (actionTitlesList.includes(action.title)) {
                console.log("Net Effect " + action.netEffect);
                if (action.netEffect !== undefined || action.netEffect !== null) {
                    clusterNetEffect += action.netEffect
                    console.log("dsfdsf " + action.id);
                    actionIds.push(action.id)
                }
                // else {
                //     console.log("invalid action")
                //     console.log(action.id)
                // }
            }
        })
        // console.log("clusterImpact")
        // console.log(clusterImpact)
        // console.log("floored")
        // console.log( Math.floor(clusterImpact / actionIds.length))
        const index = state.selectedGame.actions.clustersList.findIndex(cluster => cluster.id === clusterId);
        if (index !== -1) {
            state.selectedGame.actions.clustersList[index].actionIds = actionIds;
            state.selectedGame.actions.clustersList[index].actionTitles = actionTitlesList
            if (actionIds.length > 0) {
                state.selectedGame.actions.clustersList[index].netEffect = Math.floor(clusterNetEffect / actionIds.length);
            }
        }
    }

    updateActionKeyValue = (state, action) => {
        const {key, value} = action.payload;
        state.selectedGame.actions[key] = value;
    }

    // updateActionDeta = (state, action) => {
    //     const {id, description, role, realWorldMedia} = action.payload;
    //     const actions = [...state.selectedGame]
    // }

    addPrevalenceToCard = (state, action) => {
        const {title} = action.payload;
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.title === title);
        if (index !== -1) {
            const action = {...state.selectedGame.actions.actionsList[index]}
            action.prevalenceExists = true;
            state.selectedGame.actions.actionsList[index] = action;
        }
    }

    deletePrevalenceFromCard = (state, action) => {
        const {id} = action.payload;
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        if (index !== -1) {
            state.selectedGame.actions.actionsList[index].prevalenceExists = false;
        }
    }

    updateActionPrevalence = (state, action) => {
        const {id, index, prevalences} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]
        const actionIndex = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        const actionToUpdate = actionsList[actionIndex]
        const prevalenceList = [...actionToUpdate.prevalenceConditionsList]
        if (prevalenceList.length < 1) prevalenceList.push({});
        else {
            prevalenceList[index] = {...prevalences};
        }
        actionToUpdate.prevalenceConditionsList = prevalenceList
        actionsList[actionIndex] = actionToUpdate
        state.selectedGame.actions.actionsList = actionsList
    }

    reduceActionPrevalence = (state, action) => {
        const {id, index} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]
        const actionIndex = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        const actionToUpdate = actionsList[actionIndex]
        actionToUpdate.prevalenceConditionsList.splice(index, 1)
        actionsList[actionIndex] = actionToUpdate
        state.selectedGame.actions.actionsList = actionsList
    }

    deleteAction = (state, action) => {
        const {id} = action.payload
        const actionsList = [...state.selectedGame.actions.actionsList]
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);
        actionsList.splice(index, 1)
        state.selectedGame.actions.actionsList = actionsList
    }

    addActionTitle = (state, action) => {
        const {title, stageId, actionType} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]

        const actionToUpdate = {
            id: generateUniqueId(),
            title,
            stageId,
            type: actionType,
            wildcard: false,
            prevalenceExists: false,
            prevalenceConditionsList: [{}],
            informalOrder: -1,
            //realWorldLink: false,
            //scenarioIds: [],
            netEffect: 0,
            impact: '',
            cost: 3,
            //link: '',
            images: [],
            //actionCardUrl: '',
            description: '',
            isCriticalAction: false,
            //extraInfo: '',
            externalMaterials: [],
            embedCode: [],
            scenarioEffects: []
        }

        actionsList.push(actionToUpdate)
        state.selectedGame.actions.actionsList = actionsList
    }

    updateActionTitle = (state, action) => {
        // title, impact, roleId, netEffect, cost, realWorldLink, scenarioIds, stageId
        const {title, id} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);

        const actionToUpdate = actionsList[index]
        actionToUpdate.title = title
        actionsList[index] = actionToUpdate

        state.selectedGame.actions.actionsList = actionsList
    }

    updateActionInformalOrder = (state, action) => {
        // title, impact, roleId, netEffect, cost, realWorldLink, scenarioIds, stageId
        const {informalOrder, title} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.title === title);

        const actionToUpdate = actionsList[index]
        actionToUpdate.informalOrder = informalOrder
        actionsList[index] = actionToUpdate

        state.selectedGame.actions.actionsList = actionsList
    }


    updateActionWildcardStatus = (state, action) => {
        // title, impact, roleId, netEffect, cost, realWorldLink, scenarioIds, stageId
        const {id, wildcard} = action.payload;
        const actionsList = [...state.selectedGame.actions.actionsList]
        const index = state.selectedGame.actions.actionsList.findIndex(action => action.id === id);

        const actionToUpdate = actionsList[index]
        actionToUpdate.wildcard = wildcard
        actionsList[index] = actionToUpdate

        state.selectedGame.actions.actionsList = actionsList
    }



    updateActionDetails = (state, action) => {
        // title, impact, roleId, netEffect, cost, realWorldLink, scenarioIds, stageId
        const {
            id,
            netEffect,
            impact,
            roleId,
            scenarioIds,
            cost,
            link,
            images,
            actionCardUrl,
            wildcard,
            description,
            isCardCriticalFail,
            extraInfo,
            externalMaterials,
            embedCode,
            scenarioEffects
        } = action.payload;


        const actionsList = [...state.selectedGame.actions.actionsList]

        const index = actionsList.findIndex(action => action.id === id);


        const actionToUpdate = actionsList[index]

        actionToUpdate.cost = cost
        actionToUpdate.netEffect = netEffect
        actionToUpdate.impact = impact
        actionToUpdate.roleId = roleId
        actionToUpdate.scenarioIds = scenarioIds
        actionToUpdate.link = link
        actionToUpdate.images = images
        actionToUpdate.actionCardUrl = actionCardUrl
        actionToUpdate.wildcard = wildcard
        actionToUpdate.description = description
        actionToUpdate.isCardCriticalFail = isCardCriticalFail,
        actionToUpdate.extraInfo = extraInfo,
        actionToUpdate.externalMaterials = externalMaterials,
        actionToUpdate.embedCode = embedCode,
        actionToUpdate.scenarioEffects = scenarioEffects

        actionsList[index] = actionToUpdate
        // }


        state.selectedGame.actions.actionsList = actionsList
    }

    updateFreeActionDescription = (state, action) => {
        console.log(action.payload);
        state.selectedGame.actions.freeActionTokenDescription = action.payload
    }

    updateFreeActionConstraints = (state, action) => {
        console.log(action.payload);
        state.selectedGame.actions.freeActionConstraintsForUse = action.payload
    }

}