import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import gamesReducer from './slices/games/index';
import chatbotManagerReducer from './slices/chatbotManager';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const gamesPersistConfig = {
    key: 'games',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy'],
};

const chatbotManagerPersistConfig = {
    key: 'chatbotManager',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy'],
};
const rootReducer = combineReducers({
    games: persistReducer(gamesPersistConfig, gamesReducer),
    chatbotManager: persistReducer(chatbotManagerPersistConfig, chatbotManagerReducer),
});

export {rootPersistConfig, rootReducer};
