import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/pages/auth/Login';
import LoadingScreen from 'src/components/LoadingScreen';
import {useParams} from "react-router-dom";
import UnityGame from 'src/pages/UnityArchetype/UnityGame';
import CannotAccessGame from 'src/pages/UnityArchetype/CannotAccessGame';

import useGetMyGroupMemberships from 'src/hooks/useGetMyGroupMemberships';

//if type is public, offer the ability to login
//if type is private, require login -> check the user has access to this resource

GameAuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function GameAuthGuard({children}) {
    const { isAuthenticated, isInitialized, activeSubscription, firestoreUser, ResolveGameLink, memberGroups, getSpecificGame, loginAnonymously } = useAuth();
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);
    const {gameLink} = useParams();
    const [gameLinkData, setGameLinkData] = useState(null); //date in the link
    const [gameData, setGameData] = useState(null); //date of the actual game itself
    const [dataGathered, setDataGathered] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);

    const { getGroupMemberships } = useGetMyGroupMemberships();
    const memberGroupIds = (getGroupMemberships.data || []).map(g => g.groupID);

    useEffect( () => {
        console.log("AUTH = " + isAuthenticated);
        async function fetchData() {
            try {
              const data = await ResolveGameLink(gameLink)
              setGameLinkData(data);

                // if (!isAuthenticated && data.accessType === 'static') {
                //     await loginAnonymously();
                // }

                const game = await getSpecificGame(data.gameID);
                setGameData(game);
                setDataGathered(true);
              if (data === undefined || data === null)
                setRequestSuccessful(false);
              else
                setRequestSuccessful(true);
            } catch (error) {
              console.error('Error fetching data:', error);
              setDataGathered(true);
              setRequestSuccessful(false);
            }
        }

        async function tryLogin() {
            if (gameLinkData.accessType === 'static')
                await loginAnonymously();

            setDataGathered(true);
        }

        fetchData();
    }, [])

    if (!dataGathered || getGroupMemberships.isFetching) {
        return <LoadingScreen/>;
    }

    // if (!requestSuccessful || !gameData.completed || gameData.published != 'published') {
    //     return <CannotAccessGame reason={'This game access Token does not exist!'}></CannotAccessGame>;
    // }

    if (gameLinkData.accessType === 'static') {

        console.log(gameData.staticToken);
        console.log(gameData.accessToPlay);

        if (gameData.staticToken === gameLinkData.staticToken && gameData.accessToPlay == 'playAvailable')
            return <UnityGame gameLinkData={gameLinkData} gameLink={gameLink} />
        else
            return <CannotAccessGame reason={'Either this game is no longer available or it has been temporarily closed.'}></CannotAccessGame>;
    }

    if (gameLinkData.accessType === 'private' && !isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }

        return <Login />;
    }

    // if (gameLinkData.accessType === 'private' && !memberGroupIds.includes(gameLinkData.groupID)) {
    //     return <CannotAccessGame reason={'You do not have access to this game'}></CannotAccessGame>;
    // }

    const date = new Date();
    const UTC_time = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    if (gameLinkData.sessionEnd < UTC_time)
        return <CannotAccessGame reason={'This game session has expired!'}></CannotAccessGame>;

    if (gameLinkData.sessionStart > UTC_time)
        return <CannotAccessGame reason={'This game session has not started yet!'}></CannotAccessGame>;

    //Here, if the game is public access and the user is not authenticated then login anonymously
    if (gameLinkData.accessType === 'public'){
        console.log("PUBLIC ACCESS GAME");
    }
    
    return <UnityGame gameLinkData={gameLinkData} gameLink={gameLink}/>

    // if (requestedLocation && pathname !== requestedLocation) {
    //     setRequestedLocation(null);
    //     return <Navigate to={requestedLocation}/>;
    //}

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
