import {React, useEffect, useState, useRef} from 'react';
import useAuth from '../../hooks/useAuth';
import { Unity, useUnityContext } from "react-unity-webgl";
import {refFromURL, set, onValue, runTransaction, getDatabase, get, off} from 'firebase/database';
import LoadingScreen from 'src/components/LoadingScreen';
import BG_MessageDialog from 'src/components/BG_MessageDialog';
import axios from 'axios';
import {getApp} from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

export default function UnityGame(props){

  const {firestoreUser} = useAuth();
  const [gameState, SetGameState] = useState("");
  const gameStateRef = useRef(undefined);
  const gameRef = useRef(undefined);
  const gameToJoin = useRef(null);
  const [unityHasStarted, setUnityHasStarted] = useState(false);
  const [requestedPlayType, setRequestedPlayType] = useState(false);
  const [readyToLoadUnity, setReadyToLoadUnity] = useState(false);
  const [mySessionData, setMySessionData] = useState({
    uid: firestoreUser?.uid || 'anonymous',
    firstName: firestoreUser?.firstName || 'anonymous',
    lastName: firestoreUser?.lastName || 'anonymous',
    userName: firestoreUser?.username || 'anonymous',
    sessionID: props.gameLinkData.sessionID,
    sessionGameID: null,
    teamName: null,
    groupID: props.gameLinkData?.groupID || '',
    sessionName: props.gameLinkData?.sessionName || '',
    linkID: props.gameLink,
    gameID: props.gameLinkData.gameID
  });
  const [introVideoFinished, setIntroFinished] = useState(false);

  const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");

  const intervalInSeconds = 15;
  let keepAliveSignal = useRef(null);
  let gameID;
  let firstLoad = true;
  // let mySessionData = {
  //   uid: firestoreUser.uid,
  //   userName: firestoreUser.username,
  //   sessionID: props.gameLinkData.sessionID,
  //   sessionGameID: null, //set later
  //   teamName: null, //set later
  //   groupID: props.gameLinkData.groupID,
  //   sessionName: props.gameLinkData.sessionName,
  //   linkID: props.gameLink
  // }


  //set up the unity data context and retireive required data/functions
  const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener, initialisationError } = useUnityContext({
    loaderUrl: "/GameFiles/Build/BrightGameUnity.loader.js",
    dataUrl: "/GameFiles/Build/BrightGameUnity.data",
    frameworkUrl: "/GameFiles/Build/BrightGameUnity.framework.js",
    codeUrl: "/GameFiles/Build/BrightGameUnity.wasm",
    });

    // #region To Unity Events
    function SendPlayType_ToUnity(){
      console.log("play type is: " + props.gameLinkData.playType);
      setRequestedPlayType(true);
      sendMessage("Firebase_ComminicationManager", "SetPlayType", props.gameLinkData.playType);
    }

  //SendUserData_ToUnity
  function SendUserData_ToUnity() {
    console.log("user data is: " + JSON.stringify(mySessionData));
    sendMessage("Firebase_ComminicationManager", "ReceiveMyUserData", JSON.stringify(mySessionData));
    if (unityHasStarted === false) {
      console.log("Unity HAS STARTED!!");
      setUnityHasStarted(true);
    }
  }

    //event for when the state of the game has updated
    const onGameStateChanged = (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        SetGameState(JSON.stringify(data));
      } else {
        console.log('Data does not exist at this location.');
      }
    };

    const SendCurrentGameState_ToUnity = () =>{
      get(refFromURL(getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/"), gameToJoin.current)).then((snapshot) => {
        if (snapshot.exists()) {
            const data = snapshot.val();
            if (data !== null)
              SetGameState(JSON.stringify(snapshot.val()));
          } else {
            console.log('No data found at this location.');
          }
      });
    }
  // #endregion

    // #region Firebase util
    const FindAvailableGame = async(sessionID) => {
      const url = 'https://europe-west2-brightgame-80a0e.cloudfunctions.net/AssignGameToUser';
      const data = {'sessionID': sessionID};
      console.log("FINDING GAME: " + sessionID)
      try {
          const response = await axios.post(url, data);
          console.log("AVAILABLE GAME: " + response)
          return response.data;
      } catch (err){
          console.log("err: " + err);
          return null;
      }
  }

    const SendKeepAlive = async() => {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/Users/' + firestoreUser.uid + '/keepAlive'), new Date().getTime());
      CheckAnyUsersDisconnected();
  }

  const CheckAnyUsersDisconnected = async() => {
    const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
    get(refFromURL(db, gameToJoin.current + '/Users')).then((snapshot) => {
      if (snapshot.exists()) {
          const data = snapshot.val();

          for (const user in data) {
            
            const date = new Date().getTime();
            if (date - data[user].keepAlive > 30000)
            {
              set(refFromURL(db, gameToJoin.current + '/Users/' + user + "/ConnectionState"), "Disconnected");
              SendChatMessage("BrightBot", data[user].userName + " has disconnected");
            }
          }
        } else {
          console.log('No data found at this location.');
        }
    });
}
  // #endregion

    // #region Game Server Commands

  //event for when a card has been added to the discard or shorlist
  const AddCardToList = (cardListName, newCardInformalOrder) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/" + cardListName), (transaction) => {
        if (transaction === null)
        {
          let cardSet = new Set();
          cardSet.add(parseInt(newCardInformalOrder, 10));
          return JSON.stringify(Array.from(cardSet));
        } //else not null

        let cardData = JSON.parse(transaction);
        let cardSet = new Set(cardData);
        let oldSet = new Set(cardData);
        
        
        cardSet.add(parseInt(newCardInformalOrder, 10));
        if (oldSet !== cardSet)
        {
          SendChatMessage('BrightBot', firestoreUser.username + " added card " + newCardInformalOrder + " to " + cardListName);
          return JSON.stringify(Array.from(cardSet));
        }
      })
    }
  };

  const RemoveCardFromList = (cardListName, newCardInformalOrder) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/" + cardListName), (transaction) => {
        if (transaction === null)
        {
          return;
        } //else not null

        let cardData = JSON.parse(transaction);
        
        let cardSet = new Set(cardData);
        let oldSet = new Set(cardData);

        cardSet.delete(newCardInformalOrder);
        if (oldSet !== cardSet)
          return JSON.stringify(Array.from(cardSet));


        if (cardSet.size > 0){
          let lastElement = cardData[cardData.length - 1];
          cardSet.delete(lastElement);
          SendChatMessage('BrightBot', firestoreUser.username + " removed card " + newCardInformalOrder + " to " + cardListName);
          return JSON.stringify(Array.from(cardSet));
        }
      })
    }
  };

  //event for when a card has been placed on the hex board
  const PlaceCard = (informalOrder, hexID) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/PlacedCards"), (transaction) => {
        if (transaction === null || transaction === "[]")
        {
          let cardSet = new Set();
          cardSet.add({informalOrder: informalOrder, hexID: hexID});
          return JSON.stringify(Array.from(cardSet));
        } //else not null

        let cardData = JSON.parse(transaction);
        let cardSet = new Set(cardData);
        let oldSet = new Set(cardData);
        
        cardSet.add({informalOrder: informalOrder, hexID: hexID});
        SendChatMessage('BrightBot', firestoreUser.username + " placed card " + informalOrder + " on the hex board");
        return JSON.stringify(Array.from(cardSet));
      })
    }
  };

  //event for when a card has been removed from the hex board
  const RemovePlacedCard = (cardToRemove) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/PlacedCards"), (transaction) => {
        if (transaction === null || transaction === "[]")
        {
          return "[]";
        } //else not null

        let cardData = JSON.parse(transaction);
        let cardSet = new Set(cardData);
        let oldSet = new Set(cardData);
        
        for (let card of cardSet){
          if (card['informalOrder'] == cardToRemove)
          cardSet.delete(card);
        }

        if (oldSet !== cardSet)
        {
          SendChatMessage('BrightBot', firestoreUser.username + " removed card " + cardToRemove + " from the hex board ");
          return JSON.stringify(Array.from(cardSet));
        }
      })
    }
  };

  const SetReadyStatus = (readyStatus) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/Users/' + firestoreUser.uid +'/readyStatus'), readyStatus === 1 ? true : false);

      if (readyStatus)
        SendChatMessage('BrightBot', firestoreUser.username + " is ready");
      else
        SendChatMessage('BrightBot', firestoreUser.username + " is not ready");
      
    }
  };

  const VoteForScenario = (scenarioID) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/Users/' + firestoreUser.uid + '/VotedForScenario'), scenarioID);

      if (gameState !== "" || gameState !== undefined || gameState !== null)
        CheckVoteResult();
    }
  };

  const CheckVoteResult = () => {
    if (gameToJoin.current !== null)
    {
      let votes = {};
      let votedForScenarioID = "";
      let totalVotes = 0;

      get(refFromURL(getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/"), gameToJoin.current + "/Users")).then((snapshot) => {
        if (snapshot.exists()) {
          for (const user in snapshot.val()) {
            if (snapshot.val()[user].VotedForScenario != "" || snapshot.val()[user].VotedForScenario != undefined || snapshot.val()[user].VotedForScenario != null)
              votes[snapshot.val()[user].VotedForScenario] = (votes[snapshot.val()[user].VotedForScenario] || 0) + 1;
            }
          } else {
            console.log('No data found at this location.');
          }

          let votesForScenario = 0;
          for (const scenarioID in votes){
            totalVotes += votes[scenarioID];
            if (votes[scenarioID] > votesForScenario)
            {
              votedForScenarioID = scenarioID;
              votesForScenario = votes[scenarioID];
            }
          }
      
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/ChosenScenario"), (transaction) => {
        const connectedUsers = Object.entries(JSON.parse(gameStateRef.current).Users).filter(([key, value]) => {
          // Your filter condition here
          return value.ConnectionState !== 'Disconnected'; 
        })
        if (connectedUsers === 0)
          return;
        if (votedForScenarioID !== "" && totalVotes > Object.keys(connectedUsers).length / 2)
        {
          SetNextPageCountDown(true);
          return votedForScenarioID;
        }
      });
    });
    }
  };

  const SetNewPage = (newPage) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/GameState/CurrentPage'), newPage);
    }
  };

   //event for when a card has been removed from the hex board
   const PlaceFreeActionCard = (freeActionHex) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      runTransaction (refFromURL(db, gameToJoin.current + "/GameState/FreeActionHex"), (transaction) => {
        if (transaction === null || transaction === freeActionHex)
        {
          return transaction;
        } //else not null

        SendChatMessage('BrightBot', firestoreUser.username + " placed the free action card");
        return freeActionHex

      })
    }
  };

  const SendChatMessage = (user, message) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      const time = Date.now();
      set(refFromURL(db, gameToJoin.current + '/messages/' + time + '/user'), user);
      set(refFromURL(db, gameToJoin.current + '/messages/' + time + '/message'), message);
    }
  };

  const SetNextPageCountDown = (countDown) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/GameState/nextPageCountDown'), countDown);
    }
  };

  const SetTimerValue = (TimerValue) => {
    if (gameToJoin.current !== null)
    {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      set(refFromURL(db, gameToJoin.current + '/Users/' + firestoreUser.uid + "/currentTimerValue"), TimerValue);
    }
  };


  const Disconnect = () => {

    if (props.gameLinkData.playType == 'single')
      return;

    refFromURL(db, gameToJoin.current);
    off(refFromURL(db, gameToJoin.current));
    //unSubscribeFromStateChanges();
    clearInterval(keepAliveSignal.current);
    removeEventListener("RequestGameState",SendCurrentGameState_ToUnity);
    addEventListener("RequestUserData", SendUserData_ToUnity);
    console.log("Removed listener");
    removeEventListener("RequestPlayType",SendPlayType_ToUnity);
    removeEventListener("SetReadyStatus",SetReadyStatus);
    removeEventListener("AddToShortlist", (card) => AddCardToList("ShortlistedCards", card));
    removeEventListener("AddToDiscard",(card) => AddCardToList("DiscardedCards",card));

    removeEventListener("RemoveFromShortlist", (card) => RemoveCardFromList("ShortlistedCards", card));
    removeEventListener("RemoveFromDiscard",(card) => RemoveCardFromList("DiscardedCards",card));

    removeEventListener("PlaceCard", PlaceCard); //RemovePlacedCard
    removeEventListener("RemovePlacedCard", RemovePlacedCard); 
    removeEventListener("PlaceFreeAction", PlaceFreeActionCard); 

    removeEventListener("SendChatMessage", SendChatMessage);
    removeEventListener("SetNextPageCountDown", SetNextPageCountDown); 

    removeEventListener("SetVotedScenario", VoteForScenario);

    removeEventListener("SetNewPage", SetNewPage);
    //removeEventListener("SetTimerValue", SetTimerValue);

    //remove userFromLobby
    set(refFromURL(db, gameToJoin.current + '/Users/' + firestoreUser.uid), null);
    SendChatMessage("BrightBot", firestoreUser.username + " has disconnected");

    SendCurrentGameState_ToUnity();
    gameToJoin.current = null;
  }

  // #endregion


  //component mounted use effect
  useEffect(() => {
    document.title = 'Play';
    ///d76d956837508ac0/e291ea51
    //clearInterval(keepAliveSignal.current);
    
    console.log("added listener");
    addEventListener("RequestPlayType",SendPlayType_ToUnity);
    addEventListener("RequestUserData", SendUserData_ToUnity);

    const joinGame = async() => {
      const db = getDatabase(getApp(), "https://brightgame-80a0e-4de0eonlinegamesessions.europe-west1.firebasedatabase.app/");
      console.log("FINDING GAME 1")
      const game = await FindAvailableGame(props.gameLinkData.sessionID);
      console.log("FINDING GAME 2")

      gameID = game.split('/').pop();
      setMySessionData(prevData => ({
        ...prevData,
        sessionGameID: gameID
      }));

      gameToJoin.current = game;
      gameRef.current = refFromURL(db, game);

      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/readyStatus'), false);
      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/VotedForScenario'), "");
      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/currentPage'), 'Lobby');
      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/userName'), firestoreUser.username);
      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/keepAlive'), new Date().getTime());
      await set(refFromURL(db, game + '/Users/' + firestoreUser.uid + '/ConnectionState'), "Connected");

      const teamNameSnapshot = await get(refFromURL(db, game + '/teamName'));
      setMySessionData(prevData => ({
        ...prevData,
        teamName: teamNameSnapshot.val()
      }));
      //set(refFromURL(db, game + '/Users/' + firestoreUser.uid + "/currentTimerValue"), 15000);

      await set(refFromURL(db, game + '/GameState' + '/ChosenScenario'), "");
      keepAliveSignal.current = setInterval(SendKeepAlive, intervalInSeconds * 1000); //update the server to inform it I'm still listening. If the keepAlive is older than 30 seconds we can assume they are no longer connected
      SendChatMessage('BrightBot', firestoreUser.username + " has joined the " + teamNameSnapshot.val());
      

      addEventListener("RequestGameState",SendCurrentGameState_ToUnity);
      addEventListener("SetReadyStatus",SetReadyStatus);

      addEventListener("AddToShortlist", (card) => AddCardToList("ShortlistedCards", card));
      addEventListener("AddToDiscard",(card) => AddCardToList("DiscardedCards",card));

      addEventListener("RemoveFromShortlist", (card) => RemoveCardFromList("ShortlistedCards", card));
      addEventListener("RemoveFromDiscard",(card) => RemoveCardFromList("DiscardedCards",card));

      addEventListener("PlaceCard", PlaceCard);
      addEventListener("RemovePlacedCard", RemovePlacedCard); 
      addEventListener("PlaceFreeAction", PlaceFreeActionCard); 

      addEventListener("SendChatMessage", SendChatMessage);
      addEventListener("SetNextPageCountDown", SetNextPageCountDown); 

      addEventListener("SetVotedScenario", VoteForScenario);

      addEventListener("SetNewPage", SetNewPage);
      //addEventListener("SetTimerValue", SetTimerValue);



       onValue(refFromURL(db, game), onGameStateChanged);
      setReadyToLoadUnity(true);
    }



    if (props.gameLinkData.playType === 'multiPlay'){
      joinGame();
    }

    if (props.gameLinkData.playType === 'single'){
      //join the game normally
      setMySessionData(prevData => ({
        ...prevData,
        sessionGameID: uuidv4() //create a new unique identifier for this user playing the game. Used in results to uniquely identify this single player game in the session. Required for public access when there is no user ID
      }));
      setReadyToLoadUnity(true);
    }

    return () => {
      Disconnect();
    }
  }, []);

  //specific use effect to detect when the received data from the onValue has changed
  //needed due to the fact that sendMessage doesn't seem to fire in Arrow(=>) functions
  useEffect(() => {
    console.log(gameState);
    gameStateRef.current = gameState;
    sendMessage("Firebase_ComminicationManager", "onGameDataChanged", gameState);

    // if (gameState !== "" || gameState !== undefined)
    //sendMessage("Firebase_ComminicationManager", "ReceiveMyUserData", JSON.stringify(mySessionData));

    //sendMessage("Firebase_ComminicationManager", "SetPlayType", props.gameLinkData.playType);
  }, [gameState, mySessionData]);

  useEffect(() => {
    console.log("unityHasStarted");
    //sendMessage("Firebase_ComminicationManager", "ReceiveMyUserData", JSON.stringify(mySessionData));
  }, [unityHasStarted, mySessionData]);

  useEffect(() => {
    console.log("unityHasStarted2");
    sendMessage("Firebase_ComminicationManager", "SetPlayType", props.gameLinkData.playType);
    sendMessage("Firebase_ComminicationManager", "ReceiveMyUserData", JSON.stringify(mySessionData));
  }, [requestedPlayType]);

  if (initialisationError !== null)
  {
    Disconnect();
    return <BG_MessageDialog title="Game Initialisation Error" message={initialisationError.message}></BG_MessageDialog>;
  }

  //work out current loaded % of the Unity
  const loadingPercentage = Math.round(loadingProgression * 100);

  if (isLoaded && firstLoad === true)
  {
    //sendMessage("Firebase_ComminicationManager", "SetPlayType", props.gameLinkData.playType);
    firstLoad = false;
  }

  const handleVideoEnd = () => {
    setIntroFinished(true);
    // You can perform additional actions here when the video ends
  };

  return <div style={{ position: 'fixed', height: '100vh', width: '100vw' }}>

    {(!isLoaded || !introVideoFinished) && <video style={{ position: 'fixed', zIndex: '97', width: '100vw', height: '100vh' }} onEnded={handleVideoEnd} autoPlay muted>
      <source src="https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/front-end-assets%2FBright-Game-inro-v3.mp4?alt=media&token=92afd8b1-eef9-4529-bde3-e21ada0c182f" />
    </video>
    }

    {(!isLoaded && introVideoFinished) &&
      <LoadingScreen style={{ zIndex: '98' }} >
      </LoadingScreen>
    }
    {(!isLoaded && introVideoFinished) &&
      <div style={{ zIndex: '99', position: 'fixed', width: '100vw', height: '100vh', textAlign: 'center' }}>
        <p style={{ marginTop: '62vh', fontSize: '1.5rem' }}>Loading... ({loadingPercentage}%)</p>
        {(loadingPercentage >= 90) && <p style={{ fontSize: '1.2rem' }}>Your browser may require interaction to continue loading.</p>}
    </div>
    }

    {readyToLoadUnity && <Unity unityProvider={unityProvider} style={{ width: '100%', height: '100%' }} />}
  </div>
}

