import {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import GameAuthGuard from 'src/guards/GameAuthGuard';
// config
import {PATH_AFTER_LOGIN} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Onboarding from "../pages/auth/register/Onboarding";
import { ViewCollaboration } from 'src/pages/dashboard/Collaborations/ViewCollaboration';
import { RespondCollaboration } from 'src/pages/respond/RespondCollaboration';
import { ViewGroupInvite } from 'src/pages/dashboard/MyGroups/ViewGroupInvite';
import { CollaborationRespond } from 'src/pages/dashboard/Collaborations/MyInvitations/CollaborationRespond';
import LearnerViewGroup from 'src/pages/dashboard/MyGroups/LearnerViewGroup';

import Page404 from 'src/pages/Page404';

// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
const Loadable = (Component) => (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register/>
                        </GuestGuard>
                    ),
                },
                // {
                //     path: 'register32xkyvqmuo7npadt6c4elbi1',
                //     element: (
                //         <GuestGuard>
                //             <RegisterAllAccess/>
                //         </GuestGuard>
                //     ),
                // },
                {
                    path: 'registerLearner',
                    element: (
                        <GuestGuard>
                            <RegisterLearner/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register/:planId',
                    element: (
                        <GuestGuard>
                            <Register/>
                        </GuestGuard>
                    ),
                },
                {path: 'reset-password', element: <ResetPassword/>},
                {path: 'verify', element: <VerifyCode/>},
            ],
        },
        {
            path: 'onboarding',
            element: (
                <AuthGuard>
                    <Onboarding/>
                </AuthGuard>
            ),
        },
        {
            path: 'respond/collaboration/:id',
            element: <RespondCollaboration />
        },
        // Dashboard Routes
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: 'home', element: <Home/>},

                { path: 'testpage', element: <TestPage /> },

                {path: 'profile', element: <Profile/>},
                {path: 'profile/:id', element: <Profile/>},
                {path: 'edit-profile', element: <EditProfile/>},
                {path: 'gallery', element: <Gallery/>},
                {path: 'view-game/:id', element: <DetailedGameView/>},
                {path: 'launch-my-game/:id', element: <LaunchMyGame/>},
                {path: 'game-creator', element: <GameCreator/>},
                {path: 'my-games', element: <MyGames/>},
                {path: 'my-games/my-games-reports', element: <MyGamesReports/>},
                {path: 'game-report/:id', element: <GameReportSingle/>},
                {path: 'share-token/:gameLink', element: <ShareAccessToken /> },
                {path: 'view-token/:gameLink', element: <ViewAccessToken /> },
                {path: 'collaborations', element: <Collaborations/>},
                {path: 'collaborations/:collaborationId', element: <ViewCollaboration />},
                {path: 'collaborations/:collaborationId/respond', element: <CollaborationRespond />},
                {path: 'my-groups', element: <MyGroups/>},
                {path: 'my-groups/view-group/:id', element: <ViewGroup/>},
                {path: 'my-groups/learner-view-group/:id', element: <LearnerViewGroup/>},
                {path: 'my-groups/view-group/:id/session-results/:session', element: <SessionResults/>},
                {path: 'my-groups/:groupId/respond', element: <ViewGroupInvite />},
                {path: 'edit/:id', element: <GameCreator/>},
                {path: 'manage-game/:id', element: <GameManagement/>},
                {path: 'account', element: <Account/>},
                {path: 'creator-results', element: <CreatorResults/>},
            ],
        },

        // Main Routes
        {
            path: '/',
            children: [
                {element: <Navigate to="/auth/login" replace/>, index: true},
            ],
        },
        {
            path: 'play',
            element: (<EnterGameAccessTokenPage/>)
        },
        {
            path: 'play/:gameLink?',
            element: (<GameAuthGuard />)
        },
        {
            path: '*',
            element: (<Page404 />)
        }

    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/register/Register')));
const RegisterAllAccess = Loadable(lazy(() => import('../pages/auth/register/RegisterAllAccess')));
const RegisterLearner = Loadable(lazy(() => import('../pages/auth/register/RegisterLearner')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile/Profile')));
const EditProfile = Loadable(lazy(() => import('../pages/dashboard/Profile/EditProfile')));
const Gallery = Loadable(lazy(() => import('../pages/dashboard/Gallery/Gallery')));
const DetailedGameView = Loadable(lazy(() => import('../pages/dashboard/Gallery/DetailedGameView')));
const LaunchMyGame = Loadable(lazy(() => import('../pages/dashboard/Gallery/LaunchMyGame')));
const GameCreator = Loadable(lazy(() => import('../pages/dashboard/GameCreator/GameCreator')));
const GameManagement = Loadable(lazy(() => import('../pages/dashboard/MyGames/GameManagement')));
const MyGames = Loadable(lazy(() => import('../pages/dashboard/MyGames/MyGames')));
const MyGamesReports = Loadable(lazy(() => import('../pages/dashboard/MyGames/MyGamesReports')));
const GameReportSingle = Loadable(lazy(() => import('../pages/dashboard/MyGames/MyGameReport_Single')));
const MyGroups = Loadable(lazy(() => import('../pages/dashboard/MyGroups/MyGroups')));
const ViewGroup = Loadable(lazy(() => import('../pages/dashboard/MyGroups/ViewGroup')));
const Collaborations = Loadable(lazy(() => import('../pages/dashboard/Collaborations/Collaborations')));
const Account = Loadable(lazy(() => import('../pages/dashboard/Account/Account')));
const CreatorResults = Loadable(lazy(() => import('../pages/dashboard/Results/CreatorResultsPage')));
const SessionResults = Loadable(lazy(() => import('../pages/dashboard/MyGroups/SessionResults')));
const EnterGameAccessTokenPage = Loadable(lazy(() => import('../pages/UnityArchetype/EnterGameAccessTokenPage')));
const ShareAccessToken = Loadable(lazy(() => import('../pages/dashboard/games/ShareGameLink')));
const ViewAccessToken = Loadable(lazy(() => import('../pages/UnityArchetype/ViewAccessToken')));

const TestPage = Loadable(lazy(() => import('../pages/dashboard/testpage')));
//Unity
const Unity = Loadable(lazy(() => import('../pages/UnityArchetype/UnityGame')));
