import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import useAuth from 'src/hooks/useAuth';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import LoadingScreen from 'src/components/LoadingScreen';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';
import { LoadingDots } from 'src/components/Loading/LoadingDots';
import useGetMyGroupMemberships from 'src/hooks/useGetMyGroupMemberships';

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const GridColumn = styled(Grid)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BackLink = styled.button`
  display: inline-block;
  border: none;
  background: none;
  color: rgb(99, 115, 129);
  margin: 10px 0 10px 0;
  &:hover {
    text-decoration: underline
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const DeleteButton = styled(Button)`
  background: none;
  border-width: 1px;
  border-style: solid;
`;

export function ViewGroupInvite() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { groupId } = useParams();
  const {
    user,
    getGroup,
    respondToGroupInvite,
    withdrawGroupInvite
  } = useAuth();
  const [isDeclineInvitationDialogOpen, setIsDeclineInvitationDialogOpen] = useState(false);
  const [isWithdrawInvitationDialogOpen, setIsWithdrawInvitationDialogOpen] = useState(false);

  const { getGroupMemberships } = useGetMyGroupMemberships();

  const fetchGroup = useQuery(['group', groupId], () => getGroup(groupId));

  const mutationWithdrawInvite = useMutation({
    mutationFn: async ({ gid, mid }) => {
      await withdrawGroupInvite(gid, mid);
    },
    onSuccess: (r, variables) => {
      enqueueSnackbar("Group invitation withdrawn.");
      navigate(`/dashboard/my-groups/learner-view-group/${groupId}`);
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("An error occurred while trying to withdraw the group invitation.")
    }
  });

  const mutationRespondToInvite = useMutation({
    mutationFn: async ({ gid, status }) => {
      await respondToGroupInvite(gid, status);
    },
    onSuccess: (r, variables) => {
      const messages = {
        "accepted": "You have joined the group.",
        "declined": "You have declined the group invitation.",
      };
      const msg = messages[variables.status];
      if (msg) {
        enqueueSnackbar(msg);
      }
      navigate(variables.status === "accepted"
        ? `/dashboard/my-groups/learner-view-group/${groupId}`
        : `/dashboard/home`);
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("Error joining group.")
    }
  });

  const group = fetchGroup.data;
  const iAmOwner = group?.ownerID === user.uid;
  const iAmInvited = (getGroupMemberships.data || [])
    .some(gm => ["pending", "invited"].includes(gm.status.toLowerCase()) && gm.invitedUserEmail === user.email);

  const handleClickJoinGroup = () => {
    mutationRespondToInvite.mutate({ gid: groupId, status: "accepted" });
  }

  const handleClickDeclineInvitation = () => {
    setIsDeclineInvitationDialogOpen(true);
  }

  const handleClickWithdrawInvitation = () => {
    setIsWithdrawInvitationDialogOpen(true);
  }

  const isLoading = mutationRespondToInvite.isLoading || mutationWithdrawInvite.isLoading;

  return (
    <Page title="Group Invitation" sx={{ backgroundImage: 'linear-gradient(#FFFFFF, #E4F5FD)' }}>
      {fetchGroup.isLoading && <LoadingScreen />}
      <ConfirmationDialog
        isOpen={isDeclineInvitationDialogOpen}
        title="Decline Group Invitation"
        message="Are you sure you want to decline the invitation to join the group?"
        onClose={() => setIsDeclineInvitationDialogOpen(false)}
        onConfirm={() => {
          mutationRespondToInvite.mutate({ gid: groupId, status: "declined" });
        }}
      />
      <ConfirmationDialog
        isOpen={isWithdrawInvitationDialogOpen}
        title="Withdraw Group Invitation"
        message="Are you sure you want to withdraw the invitation?"
        onClose={() => setIsWithdrawInvitationDialogOpen(false)}
        onConfirm={() => {
          mutationWithdrawInvite.mutate({ gid: groupId, mid: user.uid });
        }}
      />
      <Scrollbar>
        <Container maxWidth={'lg'} sx={{ minHeight: '100vh' }}>
          <Stack sx={{ mt: 2 }} justifyContent="space-between" direction="row" spacing={2}>
            <Typography variant={'h3'} gutterBottom>
              Group Invitation
            </Typography>
          </Stack>
          <BackLink onClick={() => navigate("/dashboard/my-groups")}>{"< Back to Groups"}</BackLink>
          {group && (
            <StyledPaper>
              <Grid container spacing={2}>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">Group Name:</Typography>
                  <Typography variant="body1">Group Owner Username:</Typography>
                  <Typography variant="body1">Group Owner Organisation:</Typography>
                </GridColumn>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">{group.groupName}</Typography>
                  <Typography variant="body1">{group.ownerUsername}</Typography>
                  <Typography variant="body1">{group.ownerOrganisation || ""}</Typography>
                </GridColumn>
              </Grid>
              {(mutationRespondToInvite.isLoading || mutationWithdrawInvite.isLoading) && <LoadingDots />}
              <ActionButtonsContainer>
                {!iAmInvited && iAmOwner
                  && <DeleteButton disabled={isLoading} color="warning" onClick={() => handleClickWithdrawInvitation()}>Withdraw</DeleteButton>}
                {iAmInvited && <>
                  <Button disabled={isLoading} color="primary" onClick={handleClickJoinGroup}>Join Group</Button>
                  <DeleteButton disabled={isLoading} color="warning" onClick={() => handleClickDeclineInvitation()}>Decline Invitation</DeleteButton>
                </>}
              </ActionButtonsContainer>
            </StyledPaper>
          )}
        </Container>
      </Scrollbar>
    </Page>
  );
}
