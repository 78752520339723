export default class Characters {

    // add character to rolesList
    // addRoleToRolesList = (state, action) => {
    //     const {characterId, roleName} = action.payload;
    //     const rolesList = [...state.selectedGame.characters.rolesList]
    //     rolesList.push(
    //         {
    //             id: (Math.random() + 1).toString(36).substring(7),
    //             name: roleName,
    //             characterId,
    //             characterImageUrl: '',
    //             realWorldLink: false,
    //             realName: '',
    //             realRole: '',
    //             email: '',
    //         }
    //     )
    //     state.selectedGame.characters.rolesList = rolesList
    // }

    clearRoles = (state) => {
        state.selectedGame.characters.rolesList = []
    }

    updateRole = (state, action) => {
        const {roleName, characterUrl, realWorldLink, roleId, realName, howTheyHelp, email, externalLink, externalLinkTitle, selectedImage} = action.payload;

        // console.log("UPDATING ROLE IMAGES")
        // console.log(images)
        // console.log(roleName, characterUrl, realWorldLink, realName, realRole, email)
        if (!roleName) return;
        let id = roleId;
        if (roleId === '') {
            id = (Math.random() + 1).toString(36).substring(7);
        }
        const rolesList = [...state.selectedGame.characters.rolesList]
        const index = rolesList.findIndex(object => object.name === roleName);
        let roleToUpdate;
        if (index !== -1) {
            console.log(selectedImage);
            roleToUpdate = rolesList[index]
            roleToUpdate.realWorldLink = realWorldLink
            roleToUpdate.characterUrl = characterUrl
            if (realWorldLink) {
                roleToUpdate.realName = realName
                roleToUpdate.howTheyHelp = howTheyHelp
                roleToUpdate.email = email
                roleToUpdate.externalLink = externalLink
                roleToUpdate.externalLinkTitle = externalLinkTitle
                roleToUpdate.image = selectedImage

            }
            rolesList[index] = roleToUpdate
        } else {
            roleToUpdate = {
                id,
                name: roleName,
                characterUrl,
                realWorldLink,
                realName,
                email,
                howTheyHelp,
                externalLinkTitle,
                externalLink,
                selectedImage
            }
            rolesList.push(roleToUpdate)
        }

        state.selectedGame.characters.rolesList = rolesList;
    }

    // remove character from rolesList
    deleteRoleFromRolesList = (state, action) => {
        const {roleName} = action.payload;
        const rolesList = [...state.selectedGame.characters.rolesList]
        const index = rolesList.findIndex(role => role.name === roleName);
        // console.log("Deleting role")
        // console.log(index)
        if (index !== -1) {
            rolesList.splice(index, 1)
            state.selectedGame.characters.rolesList = rolesList
        }


    }

    changeCharactersKeyValue = (state, action) => {
        const {key, value} = action.payload;
        state.selectedGame.characters[key] = value;
    }

    updateRoles = (state, action) => {
        // console.log(state)
        const {roles, roleNamesList} = action.payload;
        // const {roleNamesList} = state.selectedGame.characters
        // const {stages} = state.selectedGame.processStages
        // //
        // // const rolesNamesList = new Set([...stage.roles, st])
        // //
        // // eslint-disable-next-line no-restricted-syntax
        // for (const stage of stages) {
        //     console.log(stage)
        //     console.log('stage roles', stage.roles)
        // }
        //
        //

        state.selectedGame.characters.roleNamesList = [...new Set([...roles, ...roleNamesList])];
        state.selectedGame.characters.rolesList = []
    }

    // updateAdditionalRoles = (state, action) => {
    //     const {roles} = action.payload;
    //     const {additionalRolesNameList} = state.selectedGame.characters
    //     state.selectedGame.characters.additionalRolesNameList = [...new Set([...roles, ...additionalRolesNameList])];
    // }

    // updateCharacterRoleKeyValue = (state, action) => {
    //     const {id, key, value} = action.payload;
    //     const actors = [...state.selectedGame.characters.actors];
    //     const index = actors.findIndex(object => object.id === id);
    //     actors[index][key] = value;
    //     state.selectedGame.characters.actors = actors;
    // }
    //
    // updateAdditionalCharacterRoleKeyValue = (state, action) => {
    //     const {id, key, value} = action.payload;
    //     const actors = [...state.selectedGame.characters.additionalActors];
    //     const index = actors.findIndex(object => object.id === id);
    //     actors[index][key] = value;
    //     state.selectedGame.characters.additionalActors = actors;
    // }
    //
    // addActor = (state, action) => {
    //     const id = (Math.random() + 1).toString(36).substring(7);
    //     const actors = [...state.selectedGame.characters.actors];
    //     actors.push({
    //         id,
    //         name: '',
    //         roles: [],
    //         realWorldLink: false,
    //         realName: '',
    //         email: '',
    //         characterId: ''
    //     });
    //     state.selectedGame.characters.actors = actors;
    // }
    //
    // addAdditionalActor = (state, action) => {
    //     const id = (Math.random() + 1).toString(36).substring(7);
    //     const actors = [...state.selectedGame.characters.additionalActors];
    //     actors.push({
    //         id,
    //         name: '',
    //         roles: [],
    //         realWorldLink: false,
    //         realName: '',
    //         email: '',
    //         characterId: ''
    //     });
    //     state.selectedGame.characters.additionalActors = actors;
    // }
    //
    // deleteActor = (state, action) => {
    //     const {id} = action.payload;
    //     const actors = [...state.selectedGame.characters.actors];
    //     const index = actors.findIndex(object => object.id === id);
    //     actors.splice(index, 1);
    //     state.selectedGame.characters.actors = actors;
    // }
    //
    // deleteAdditionalActor = (state, action) => {
    //     const {id} = action.payload;
    //     const actors = [...state.selectedGame.characters.additionalActors];
    //     const index = actors.findIndex(object => object.id === id);
    //     actors.splice(index, 1);
    //     state.selectedGame.characters.additionalActors = actors;
    // }
}