// Removed the requied, optional divide from the scenario, not sure what it is for
export default class Scenarios {
    addScenario = (state, action) => {
        const {scenario} = action.payload;
        const scenarios = [...state.selectedGame.scenarios.scenarios]
        scenarios.push(scenario);
        state.selectedGame.scenarios.scenarios = scenarios;
    }

    updateScenario = (state, action) => {
        const {scenario, index} = action.payload;
        state.selectedGame.scenarios.scenarios[index] = scenario;
    }

    deleteScenario = (state, action) => {
        const {index} = action.payload;
        const scenarios = [...state.selectedGame.scenarios.scenarios];
        scenarios.splice(index, 1);
        state.selectedGame.scenarios.scenarios = scenarios;
    }

    addKeyFact = (state, action) => {
        const {keyFact} = action.payload;
        const keyFacts = [...state.selectedGame.scenarios.keyFacts]
        keyFacts.push(keyFact);
        state.selectedGame.scenarios.keyFacts = keyFacts;
    }

    updateKeyFact = (state, action) => {
        const {updatedKeyFact, index} = action.payload;
        const keyFacts = [...state.selectedGame.scenarios.keyFacts]
        keyFacts[index] = updatedKeyFact;
        state.selectedGame.scenarios.keyFacts = keyFacts;
    }

    deleteKeyFact = (state, action) => {
        const {index} = action.payload;
        const keyFacts = [...state.selectedGame.scenarios.keyFacts]
        keyFacts.splice(index, 1);
        state.selectedGame.scenarios.keyFacts = keyFacts;
    }

    setFirstVisit = (state, action) => {
        const {firstVisit} = action.payload;
        state.selectedGame.scenarios.firstVisit = firstVisit;
    }
}