
// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from "react-dom/client";
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
// redux
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import {persistor, store} from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {enableMapSet} from "immer";

// ----------------------------------------------------------------------

//console.log(process.env.REACT_APP_STRIPE_PUBLISHING_KEY)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHING_KEY, {apiVersion: "2023-08-16"});
enableMapSet();

const root = createRoot(document.getElementById("root"))

root.render(
    <AuthProvider>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                        <CollapseDrawerProvider>
                            <Elements stripe={stripePromise}>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </Elements>
                        </CollapseDrawerProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
