import { useSnackbar } from 'notistack';
import useAuth from './useAuth';
import { useMutation } from '@tanstack/react-query';

function useUpdateCollaborationStatus() {
  const { enqueueSnackbar } = useSnackbar();
  const { updateCollaborationStatus: update } = useAuth();

  const mutationUpdateCollaborationStatus = useMutation(
    async ({ collaborationId, status, handleSuccess }) => {
      return await update(collaborationId, status);
    },
    {
      onSuccess: (data, variables) => {
        if (data) {
          variables?.handleSuccess?.();
        } else {
          enqueueSnackbar('Error updating collaboration status.', { variant: 'error' });
        }
      },
      onError: (err) => {
        enqueueSnackbar('Error updating collaboration status.', { variant: 'error' });
      },
    }
  );

  const updateCollaborationStatus = async (collaborationId, status, handleSuccess) => {
    await mutationUpdateCollaborationStatus.mutateAsync({ collaborationId, status, handleSuccess });
  };

  return {
    updateCollaborationStatus,
    isLoading: mutationUpdateCollaborationStatus.isLoading,
  };
}

export default useUpdateCollaborationStatus;
