import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import styled from '@emotion/styled';

import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import LoadingIndicator from 'src/components/Loading/LoadingIndicator';

const StyledPaper = styled(Paper)`
  position: relative;
  padding: 24px;
`;

const GridColumn = styled(Grid)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BackLink = styled.button`
  display: block;
  border: none;
  background: none;
  color: rgb(99, 115, 129);
  margin: 10px 0 10px 0;
  &:hover {
    text-decoration: underline
  }
`;

const CollaborationStatus = styled(Typography)`
  text-transform: capitalize;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const DeleteButton = styled(Button)`
  background: none;
  border-width: 1px;
  border-style: solid;
`;

export function ViewCollaboration() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { collaborationId } = useParams();
  const { user, getCollaboration, updateCollaborationStatus, resendCollaborationRequest } = useAuth();
  const [dialogWidthdrawCollaborationIsOpen, setDialogWidthdrawCollaborationIsOpen] = useState(false);

  const fetchCollaboration = useQuery(['collaboration', collaborationId], () => getCollaboration(collaborationId));

  const mutationUpdateCollaborationStatus = useMutation({
    mutationFn: async (args) => {
      return await updateCollaborationStatus(args.collaborationId, args.status);
    },
    onSuccess: (r) => {
      if (r) {
        enqueueSnackbar("Collaboration status updated.");
        navigate(`/dashboard/collaborations`);
      } else {
        enqueueSnackbar("Error updating collaboration status.", { variant: "error" });
      }
    },
    onError: (err) => {
      enqueueSnackbar("Error updating collaboration status.", { variant: "error" });
    }
  });

  const mutationResendCollaborationRequest = useMutation({
    mutationFn: async (args) => {
      return await resendCollaborationRequest(args.collaborationId);
    },
    onSuccess: (r) => {
      if (r) {
        enqueueSnackbar("Collaboration invitation sent.");
      } else {
        enqueueSnackbar("Error resending invitation.", { variant: "error" });
      }
    },
    onError: (err) => {
      enqueueSnackbar("Error resending invitation.", { variant: "error" });
    }
  });

  const collaboration = fetchCollaboration.data;

  const currentUserIsInviter = collaboration && user.uid === collaboration.inviterUserId;
  const currentUserIsInvited = collaboration && user.uid === collaboration.invitedUserId;

  const isLoading = mutationUpdateCollaborationStatus.isLoading || mutationResendCollaborationRequest.isLoading;

  return (
    <Page title="Collaborations" sx={{ backgroundImage: 'linear-gradient(#FFFFFF, #E4F5FD)' }}>
      {fetchCollaboration.isLoading && <LoadingScreen />}
      <ConfirmationDialog
        isOpen={dialogWidthdrawCollaborationIsOpen}
        title="Withdraw collaboration request"
        message="Are you sure you want to withdraw the collaboration request?"
        onClose={() => setDialogWidthdrawCollaborationIsOpen(false)}
        onConfirm={() => {
          // TODO: call Firebase function to withdraw collaboration.
        }}
      />
      <Scrollbar>
        <Container maxWidth={'lg'} sx={{ minHeight: '100vh' }}>
          <Stack sx={{ mt: 2 }} justifyContent="space-between" direction="row" spacing={2}>
            <Typography variant={'h3'} gutterBottom>
              Collaboration
            </Typography>
          </Stack>
          <StyledPaper>
            <LoadingIndicator isLoading={isLoading} />
            {collaboration && <>
              <Grid container spacing={2}>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">Game:</Typography>
                  <Typography variant="body1">Collaborator Role:</Typography>
                  <Typography variant="body1">Inviter User:</Typography>
                  <Typography variant="body1">Invited User:</Typography>
                  <Typography variant="body1">Invitation Status:</Typography>
                </GridColumn>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">{collaboration.gameName}</Typography>
                  <Typography variant="body1">{collaboration.role}</Typography>
                  <Typography variant="body1">{collaboration.inviterUserName}</Typography>
                  <Typography variant="body1">{collaboration.invitedUserName}</Typography>
                  <CollaborationStatus variant="body1">{collaboration.status}</CollaborationStatus>
                </GridColumn>
              </Grid>
              <ActionButtonsContainer>
                {currentUserIsInvited && collaboration?.status === "pending"
                  && <Button disabled={isLoading} color="primary" onClick={() => {
                    mutationUpdateCollaborationStatus.mutate({ collaborationId, status: "accepted" })
                  }}>Accept</Button>}
                {currentUserIsInviter && collaboration?.status === "pending"
                  && <Button disabled={isLoading} color="primary" onClick={() => {
                    mutationResendCollaborationRequest.mutate({ collaborationId })
                  }}>Resend</Button>}
                {currentUserIsInviter && collaboration?.status === "pending"
                  && <DeleteButton disabled={isLoading} color="warning" onClick={() => setDialogWidthdrawCollaborationIsOpen(true)}>Withdraw</DeleteButton>}
              </ActionButtonsContainer>
            </>}
          </StyledPaper>
        </Container>
      </Scrollbar>
    </Page>
  );
}
