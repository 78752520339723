import * as Yup from 'yup';
import {generateUsername} from "unique-username-generator";
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Alert, IconButton, InputAdornment, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import {FormProvider, RHFTextField} from '../../../components/hook-form';
import Iconify from "../../../components/Iconify";
import DropdownSelector from "../../../components/form-inputs/DropdownSelector";
import React, {useState} from "react";
// ----------------------------------------------------------------------

export default function OnboardForm(props) {

    const {updateUserProfileData, firestoreUser} = useAuth();
    const [orgType, setOrgType] = useState('None/Personal Account')


    const isMountedRef = useIsMountedRef();

    const OnboardSchema = Yup.object().shape({
        organisation: Yup.string().required('Organisation name is required'),
        displayName: Yup.string(),
        username: Yup.string().required('Username is required'),
    });

    const defaultValues = {
        organisation: '', organisationType: orgType, username: generateUsername(), displayName: '',
    };

    const methods = useForm({resolver: yupResolver(OnboardSchema), defaultValues});

    const {
        reset,
        setError, setValue, handleSubmit, formState: {errors, isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        try {
            const res = await updateUserProfileData({
                organisation: data.organisation,
                organisationType: orgType,
                username: data.username,
                displayName: data.displayName,
                onboardStep: 1,
            })
            if (res) {
                props.setCurrentStep(1);
            } else {
                throw Error('Error saving, please try again')
            }
        } catch (error) {
            console.error(error);
            reset();
            if (isMountedRef.current) {
                setError('afterSubmit', error);
            }
        }
    };

    const skipDataEntry = async () => {
        try {
            const res = await updateUserProfileData({
                onboardStep: 1,
            })
            if (res) {
                props.setCurrentStep(1);
            } else {
                throw Error('Error saving, please try again')
            }
        } catch (error) {
            console.error(error);
            reset();
            if (isMountedRef.current) {
                setError('afterSubmit', error);
            }
        }
    }

    const handleDropdownChange = (key, value) => {
        setOrgType(value)
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                <RHFTextField name="organisation" label="Enter your organisation"/>

                <Typography variant="p" sx={{paddingBottom: '5px'}}>
                    Enter your organisation type
                </Typography>
                <DropdownSelector
                    objectKey={"organisationType"}
                    optionList={['None/Personal Account', 'School', 'College', 'Sole Trader', 'Microfirm (Less than 10 employees)', 'Small Company (11-250 employees)', 'Large Company (251+ Employees)', 'University']}
                    placeholder={'Select an action to add'}
                    value={orgType}
                    handleDropdownChange={handleDropdownChange}
                    sx={{marginTop: '0px !important'}}
                />
                <RHFTextField name="displayName" label="Name (optional)"/>
                <RHFTextField
                    name="username"
                    label="Enter a username"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setValue('username', generateUsername())}>
                                <Iconify icon={'mdi:wand'}/>
                            </IconButton>
                        </InputAdornment>),
                    }}
                />
                <Stack direction="row" alignItems="center" gap={3} justifyContent={'end'}>
                    <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}
                                   onClick={skipDataEntry}
                                   sx={{width: 200, backgroundColor: 'grey', borderRadius: '5px'}}>
                        Maybe Later
                    </LoadingButton>
                    <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}
                                   sx={{width: 200, backgroundColor: '#1F8E97', borderRadius: '5px'}}>
                        Save and continue
                    </LoadingButton>
                </Stack>


            </Stack>
        </FormProvider>);
}
