export default class Validation {
  hasError = (state, action) => {
    state.isLoading = false;
    state.retrievingData = false;
    state.error = action.payload;
  };

  changeStepValidation = (state, action) => {
    state.validatedStep = action.payload;
    state.error = null;
  };

  completeGame = (state, action) => {
    state.selectedGame.completed = action.payload;
  };
}
