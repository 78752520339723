import {useEffect, useState} from 'react';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Container, Grid, Typography} from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// routes
// components
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
// sections
import {OnboardForm} from "../../../sections/auth/onboard";
import Upsell from "../../../sections/auth/onboard/upsell-cards/Upsell";
import {getProduct, getStripePayments} from "@stripe/firestore-stripe-payments";
import {getApp} from "firebase/app";
import sidePicture from '../../../assets/onboarding/onboardsidepic.png';
import { accountLicenseTypes } from 'src/constants/accountOptions';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: '600px',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(2, 0),
}));

const SectionStyle = styled('div')(({theme}) => ({
    width: '100%',
    maxWidth: 350,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(0, 0, 0, 0),
    objectFit: 'fill'
}));


// ----------------------------------------------------------------------

export default function Onboarding() {
    const {firestoreUser, isSeatedUser} = useAuth();
    const app = getApp();
    const payments = getStripePayments(app, {
        productsCollection: "products",
        customersCollection: "users",
    });
    const {onboardStep, chosenPlanId} = firestoreUser;
    const [chosenProduct, setChosenProduct] = useState({});
    const [loading, setLoading] = useState(true);

    const mdUp = useResponsive('up', 'md');

    useEffect(() => {
        async function fetchProducts() {
            const retrievedChosenProduct = await getProduct(payments, chosenPlanId, {includePrices: true,});
            setChosenProduct(retrievedChosenProduct)
            setLoading(false);
        }

        fetchProducts()
    }, []);

    const [currentStep, setCurrentStep] = useState(onboardStep ?? 0);


    const showSelectedSection = () => {
        switch (currentStep) {
            case 0:
                return <>
                    {mdUp && (
                        <>
                            <HeaderStyle>
                                <Logo/>
                            </HeaderStyle>
                            <SectionStyle>
                                <img
                                    alt="register"
                                    src={sidePicture}
                                    style={{objectFit: 'cover', height: '100%'}}
                                />
                            </SectionStyle>
                        </>
                    )}
                    <Box sx={{maxWidth: '100%', display: 'flex', justifyContent: 'center', m: 2}}>

                        <Grid container spacing={1}>
                            <Grid item xs={7}>
                                <ContentStyle>
                                    <Box sx={{mb: 5, display: 'flex'}}>
                                        <Box sx={{flexGrow: 1}}>
                                            <Typography variant="h4" gutterBottom>
                                                Welcome to BrightGame!
                                            </Typography>
                                            <Typography color='#1F8E97' variant="h6" gutterBottom>
                                                Lets get you registered...
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <OnboardForm setCurrentStep={setCurrentStep}/>
                                </ContentStyle>

                            </Grid>

                            <Grid item xs={5} display="flex" justifyContent="center" flexDirection={'column'}>
                                <>
                                    <Typography variant={'h4'} gutterBottom fontWeight="fontWeightBold">
                                        We are delighted you have decided to join us and get involved!
                                    </Typography>
                                    <Typography variant={'h4'} color='#1F8E97' fontWeight='bold'>
                                        We hope you enjoy being part of our community of Serious Learning Game creators!
                                    </Typography>
                                </>
                            </Grid>
                        </Grid>
                    </Box>
                </>

            case 1:
                //window.location.assign(window.location.origin + '/dashboard/Home');
                if (firestoreUser.licenseType == accountLicenseTypes.licenseOwner)
                    return <Upsell loading={loading} chosenProduct={chosenProduct}/>
                else if (isSeatedUser)
                    window.location.assign(window.location.origin + '/dashboard/Home');
            default:
                return <Container/>
        }
    }

    return (
        <Page title="Onboarding">
            <RootStyle>
                {showSelectedSection()}
            </RootStyle>
        </Page>
    );
}
