import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {Box, Button, Drawer, Stack, Typography} from '@mui/material';
// hooks
import {useSnackbar} from "notistack";
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useAuth from "../../../hooks/useAuth";
import useHasAccountType from 'src/hooks/useHasAccountType';
// config
import {ICON, NAVBAR} from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import {NavSectionVertical} from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import {PATH_AUTH} from "../../../routes/paths";
import {ReactComponent as BrightGameLogo} from '../../../assets/logo.svg';
import {ReactComponent as UserLogo} from '../../../assets/ic_user_profile.svg';
import { learnerNavConfig } from './NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({isOpenSidebar, onCloseSidebar}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const { user, logout } = useAuth();
    const isCurrentUserCreator = useHasAccountType("creator");

    const {pathname} = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const {isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave} =
        useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate(PATH_AUTH.login, {replace: true});
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', {variant: 'error'});
        }
    };

    const renderContent = (
        <Scrollbar
            sx={{
                width: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                height: 1,
                overflow: 'hidden',
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    ...(isCollapse && {alignItems: 'center'}),
                }}
            >
                <BrightGameLogo style={{height: 50, width: 50}}/>
            </Stack>

            {/* <NavSectionVertical navConfig={(firestoreUser.licenseType === accountLicenseTypes.learner || isCurrentUserCreator) ? learnerNavConfig : navConfig} isCollapse={isCollapse} /> */}
            <NavSectionVertical navConfig={isCurrentUserCreator ? navConfig : learnerNavConfig} isCollapse={isCollapse} />

            <Box sx={{flexGrow: 1}}/>
            <Button sx={{backgroundColor: 'white'}}  onClick={() => handleLogout()}>
                {/* <UserLogo style={{height: 50, width: 50, marginLeft: 2}}/> */}
                <Typography variant="subtitle1" align="center">Logout</Typography>
            </Button>

            <Box
                component="span"
                sx={{
                    mr: 1,
                    width: ICON.NAVBAR_ITEM_HORIZONTAL,
                    height: ICON.NAVBAR_ITEM_HORIZONTAL,
                    '& svg': {width: '100%', height: '100%'},
                }}
            />
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
            }}
        >
            {!isDesktop && (
                <Drawer open={isOpenSidebar} onClose={onCloseSidebar}
                        PaperProps={{sx: {width: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH}}}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'solid',
                            bgcolor: 'background.default',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
