export default class LearningObjectives {

    addLearningObjective = (state, action) => {
        const {learningObjective} = action.payload
        const learningObjectives = [...state.selectedGame.learningObjectives.learningObjectivesList]
        learningObjectives.push(learningObjective);
        state.selectedGame.learningObjectives.learningObjectivesList = learningObjectives;
    }

    updateLearningObjective = (state, action) => {
        const {learningObjective, index} = action.payload
        const learningObjectives = [...state.selectedGame.learningObjectives.learningObjectivesList]
        learningObjectives[index] = learningObjective;
        state.selectedGame.learningObjectives.learningObjectivesList = learningObjectives;
    }

    addLearningImpact = (state, action) => {
        const {learningImpact} = action.payload
        const newLearningImpactsList = [...state.selectedGame.learningObjectives.learningImpactsList]
        newLearningImpactsList.push(learningImpact);
        state.selectedGame.learningObjectives.learningImpactsList = newLearningImpactsList;
    }

    updateLearningImpact = (state, action) => {
        const {learningImpact, index} = action.payload
        const newLearningImpactsList = [...state.selectedGame.learningObjectives.learningImpactsList]
        newLearningImpactsList[index] = learningImpact;
        state.selectedGame.learningObjectives.learningImpactsList = newLearningImpactsList;
    }

    deleteLearningObjective = (state, action) => {
        const {index} = action.payload;
        const learningObjectives = [...state.selectedGame.learningObjectives.learningObjectivesList]
        learningObjectives.splice(index, 1);
        state.selectedGame.learningObjectives.learningObjectivesList = learningObjectives;
    }

    updateScopeOfDevelopment = (state, action) => {
        state.selectedGame.learningObjectives.scopeOfDevelopment = action.payload;
    }

    changeKeyMessage = (state, action) => {
        const {key,value} = action.payload
        const newKeyMessages = {...state.selectedGame.learningObjectives.keyMessages}
        newKeyMessages[key] = value;
        state.selectedGame.learningObjectives.keyMessages = newKeyMessages;
    }
}