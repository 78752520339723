import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import styled from '@emotion/styled';

import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useUpdateCollaborationStatus from 'src/hooks/useUpdateCollaborationStatus';
import { LoadingButton } from '@mui/lab';

const StyledPaper = styled(Paper)`
  position: relative;
  padding: 24px;
`;

const GridColumn = styled(Grid)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CollaborationStatus = styled(Typography)`
  text-transform: capitalize;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const DeleteButton = styled(Button)`
  background: none;
  border-width: 1px;
  border-style: solid;
`;

export function CollaborationRespond() {
  const { collaborationId } = useParams();
  const { user, getCollaboration } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [dialogDeclineCollaborationIsOpen, setDialogDeclineCollaborationIsOpen] = useState(false);
  const { updateCollaborationStatus, isLoading: isUpdatingCollaborationStatus } = useUpdateCollaborationStatus();

  const fetchCollaboration = useQuery(['collaboration', collaborationId], () => getCollaboration(collaborationId));

  const collaboration = fetchCollaboration.data;

  const handleAcceptCollaboration = () => {
    updateCollaborationStatus(
      collaborationId,
      "accepted",
      () => {
        enqueueSnackbar("Collaboration invitation accepted!");
      });
  };

  const handleDeclineCollaboration = () => {
    updateCollaborationStatus(
      collaborationId,
      "declined",
      () => {
        setDialogDeclineCollaborationIsOpen(false);
        enqueueSnackbar("Collaboration invitation declined!");
      });
  };

  const handleViewGame = () => {
    navigate(`/dashboard/edit/${collaboration.gameId}`);
  };

  const currentUserIsInvited = collaboration && user.uid === collaboration.invitedUserId;

  const isLoading = fetchCollaboration.isLoading || isUpdatingCollaborationStatus;

  return (
    <Page title="Collaboration Invitation" sx={{ backgroundImage: 'linear-gradient(#FFFFFF, #E4F5FD)' }}>
      {fetchCollaboration.isLoading && <LoadingScreen />}
      <ConfirmationDialog
        isOpen={dialogDeclineCollaborationIsOpen}
        title="Decline collaboration request"
        message="Are you sure you want to decline the collaboration request?"
        onClose={() => setDialogDeclineCollaborationIsOpen(false)}
        onConfirm={handleDeclineCollaboration}
        isConfirmButtonLoading={isUpdatingCollaborationStatus}
      />
      <Scrollbar>
        <Container maxWidth={'lg'} sx={{ minHeight: '100vh' }}>
          <Stack sx={{ mt: 2 }} justifyContent="space-between" direction="row" spacing={2}>
            <Typography variant={'h3'} gutterBottom>
              Collaboration Invitation
            </Typography>
          </Stack>
          <StyledPaper>
            {collaboration && <>
              <Grid container spacing={2}>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">Game:</Typography>
                  <Typography variant="body1">Collaborator Role:</Typography>
                  <Typography variant="body1">Inviter User:</Typography>
                  <Typography variant="body1">Invited User:</Typography>
                  <Typography variant="body1">Invitation Status:</Typography>
                </GridColumn>
                <GridColumn item xs={6} style={{ maxWidth: 400 }}>
                  <Typography variant="body1">{collaboration.gameName}</Typography>
                  <Typography variant="body1">{collaboration.role}</Typography>
                  <Typography variant="body1">{collaboration.inviterUserName}</Typography>
                  <Typography variant="body1">{collaboration.invitedUserName}</Typography>
                  <CollaborationStatus variant="body1">{collaboration.status}</CollaborationStatus>
                </GridColumn>
              </Grid>
              <ActionButtonsContainer>
                {currentUserIsInvited && collaboration?.status === "accepted" &&
                  <Button disabled={isLoading} color="primary" onClick={handleViewGame}>View Game</Button>
                }
                {currentUserIsInvited && !["accepted", "declined", "withdrawn"].includes(collaboration?.status?.toLowerCase()) &&
                  <LoadingButton loading={isUpdatingCollaborationStatus} disabled={isLoading} color="primary" onClick={handleAcceptCollaboration}>Accept</LoadingButton>
                }
                {currentUserIsInvited && !["declined", "withdrawn"].includes(collaboration?.status?.toLowerCase()) &&
                  <DeleteButton disabled={isLoading} color="warning" onClick={() => setDialogDeclineCollaborationIsOpen(true)}>Decline</DeleteButton>
                }
              </ActionButtonsContainer>
            </>}
          </StyledPaper>
        </Container>
      </Scrollbar>
    </Page>
  );
}
