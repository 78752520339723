import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';

const StyledDialogContent = styled(DialogContent)`
  padding-top: 16px !important;
`;

export default function ConfirmationDialog({ isOpen, title, message, onConfirm, onClose, isConfirmButtonLoading }) {
  return (<Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent><p>{message}</p></StyledDialogContent>
      <DialogActions>
        <Button disabled={isConfirmButtonLoading} onClick={() => onClose()}>Cancel</Button>
        <LoadingButton loading={isConfirmButtonLoading} onClick={() => onConfirm()}>Confirm</LoadingButton>  
      </DialogActions>
    </Dialog>)
}