import styled from "@emotion/styled";
import { LoadingDots } from "./LoadingDots";

const LoadingContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: 50px;
`;

export default function LoadingIndicator({ isLoading }) {
  return (
    isLoading ?
      (<LoadingContainer>
        <LoadingDots />
      </LoadingContainer >) : <></>
  );
}