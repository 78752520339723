import {selectBackground} from "../../index";

export default class GameDesign {
    selectColourPalette = (state, action) => {
        const {themeObject} = action.payload;
        console.log('themeObject');
        console.log(themeObject);
        //state.selectedGame.gameDesign.selectedTheme = themeObject;
        return {
        ...state,
        selectedGame: {
            ...state.selectedGame,
            gameDesign: {
                ...state.selectedGame.gameDesign,
                selectedTheme: themeObject
            }
        }
    };
    }

    selectActionCardStyle = (state, action) => {
        const { selectedCardStyle, defaultCards, roleCards, wildcardsCards } = action.payload;


        // console.log(defaultCards);
        // console.log(roleCards);
        // console.log(wildcardsCards);

        // console.log('selectedCardStyle');
        // console.log(selectedCardStyle);

        state.selectedGame.gameDesign.selectedCardStyle = selectedCardStyle;


 // Check if actionCardPaths exists in the state
 if (!state.selectedGame.gameDesign.actionCardPaths) {
    // If it doesn't exist, create it and add the new data
    state.selectedGame.gameDesign.actionCardPaths = {
        defaultCards: defaultCards,
        roleCards: roleCards,
        wildcardsCards: wildcardsCards
    };
} else {
    // If it exists, update the existing data
    state.selectedGame.gameDesign.actionCardPaths.defaultCards = defaultCards;
    state.selectedGame.gameDesign.actionCardPaths.roleCards = roleCards;
    state.selectedGame.gameDesign.actionCardPaths.wildcardsCards = wildcardsCards;
}


    }

    updateGameDesignKeyValue = (state, action) => {
        const {key, value} = action.payload;
        state.selectedGame.gameDesign[key] = value;
    }

    selectBackground = (state, action) => {
        // console.log('Setting background to: ');
        // console.log(action.payload);
        // console.trace();
        state.selectedGame.gameDesign.selectedBackground = action.payload;
    }

    // Bit more clarity on what background upload is doing here? Where are presets from
    uploadBackground = (state, action) => {
        const {file} = action.payload;
        state.selectedGame.gameDesign.background = file;
    }

    toggleAcceptedLogoTerms = (state, action) => {
        const {toggleState} = action.payload;
        state.selectedGame.gameDesign.acceptedLogoTerms = toggleState;
    }

    uploadLogo = (state, action) => {
        //const {url} = action.payload;
        //console.log("STATE: " + url);
        // dispatch(selectBackground({url: URL.createObjectURL(file)}));
        state.selectedGame.gameDesign.logo = action.payload;;
    }
}
