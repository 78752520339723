import { initialState } from '../../index';

export default class AboutTheGame {
  changeAboutGameKeyValue = (state, action) => {
    const { key, value } = action.payload;
    state.selectedGame.aboutTheGame[key] = value;
    state.selectedGame.error = null;
  };

  setGameCoverImage = (state, action) => {
    console.log("Setting thing here");
    state.selectedGame.aboutTheGame.coverImageURL = action.payload;
  };

  setGameId = (state, action) => {
    state.selectedGame.id = action.payload;
    state.gamesList.push(state.selectedGame);
  };

  resetGameState = (state, action) => {
    state.selectedGame = initialState.selectedGame;
  };

}
