// @mui
// redux
import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Stack, Tab, Tabs, Typography, Card} from "@mui/material";
import { TextField, MenuItem } from '@mui/material';
import {ReactComponent as BrightGameLogo} from 'src/assets/logo.svg';
import {NavLink as RouterLink} from "react-router-dom";

//<RouterLink to={`${PATH_DASHBOARD.viewGroup}/${groupKey}`} style={{textDecoration: 'none'}}>
// routes
// hooks
// components
import Page from 'src/components/Page';


export default function CannotAccessGame(props) {

    const [token, setToken] = React.useState(0);

    return (
        // <Page title="My Groups" sx={{backgroundImage: 'linear-gradient(#FFFFFF, #E4F5FD)', display: 'flex', height: '100vh'}}></Page>
        <Page title="My Groups" sx={{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/backgrounds%2Ftheme-3%2FBackgroundA3_v2.png?alt=media&token=21ea4093-7680-4795-ab67-01287e321cb2)', display: 'flex', height: '100vh'}}>
            <Stack sx={{width: '100%'}} direction="column" spacing={0}>
            {/* <BrightGameLogo style={{margin: 'auto', marginBottom: '0', width: '200px', height: '200px'}}></BrightGameLogo> */}
            <Card style={{margin: 'auto', width: '450px', height: 'auto', minHeight: '216px'}}>
                <Stack sx={{mt: 2, marginLeft: '25px', marginRight: '25px', marginBottom: '25px'}} justifyContent="space-between" direction="column" spacing={2}>
                        <Typography style={{marginTop: '0', borderBottom: '1px solid #bbb', textAlign: 'center'}} variant={'h6'} gutterBottom>Unable To Access Game</Typography>
                        <Typography style={{marginTop: '15px', marginBottom: '15px', textAlign: 'center'}} variant={'h7'} gutterBottom>{props.reason}</Typography>
                        <RouterLink to={'/play'} style={{textDecoration: 'none'}}>
                            <Button variant="contained"
                                onClick={async () => {
                                }}
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '10px',
                                    backgroundColor: '#1F8E97',
                                    color: 'white',
                                    boxShadow: 'none',
                                    borderColor: 'black',
                                    border: '1px',
                                    fontWeight: 'light',
                                    fontSize: '1rem',
                                }}> Try Another Token? 
                            </Button>
                        </RouterLink>
                    </Stack>
            </Card>
            </Stack>

        </Page>
    );
}




