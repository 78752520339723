import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";

export function RespondCollaboration() {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/auth/register?collaborationId=${id}`);
    }, 2000)
  }, []);

  return (
    <LoadingScreen />
  );
}