// form
// @mui
import {Box, Button, Card, CardContent, CircularProgress, Container, Grid, Link, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../hooks/useAuth";
import React, {useState} from 'react';
import {stripeProductIds} from "../../../../constants/stripeProductIds";
import {ReactComponent as BrightGameLogo} from "../../../../assets/logo_round.svg";

// hooks
// components
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({theme}) => ({
    backgroundColor: '#c8e8e8',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    maxHeight: '95vh',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(7, 7),
}));

const LeftSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(10, 40, 10, 50),
    borderRadius: '15px 0px 0px 15px'

}));

const RightSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: '#1f8e97',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 7, 2, 7),
    borderRadius: '0px 15px 15px 0px',
    alignItems: 'center'
}));

const CheckListItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'left',
    margin: '12px'
}));

export default function UpsellCard({leftContent, rightContent}) {

    const {subscribeToPlan} = useAuth();
    const [loading, setLoading] = useState(false);


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', pr: { xs: 2, sm: 3, md: 15 }, pl: { xs: 2, sm: 3, md: 15 } }}>
        <Container sx={{maxWidth: '1130px'}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={6} lg={6} xl={6} sx={{backgroundColor: 'white', borderRadius: '10px 0 0 10px'}}>
                                    <BrightGameLogo style={{height: 75, width: 75}}/>
                                    {leftContent}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6} sx={{backgroundColor: '#1f8e97', borderRadius: '0 10px 10px 0'}}>
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {rightContent}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}
