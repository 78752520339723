export default class LearningRetainment {
    changeKeyFactName = (state, action) => {
        const {name, index} = action.payload;
        const keyFacts = [...state.selectedGame.learnerRetainment.keyFacts]
        keyFacts[index] = name;
        state.selectedGame.learnerRetainment.keyFacts = keyFacts;
    };

    addKeyFact = (state, action) => {
        const keyFacts = [...state.selectedGame.learnerRetainment.keyFacts]
        keyFacts.push('');
        state.selectedGame.learnerRetainment.keyFacts = keyFacts;
    };

    deleteKeyFact = (state, action) => {
        const {index} = action.payload;
        const keyFacts = [...state.selectedGame.learnerRetainment.keyFacts]
        keyFacts.splice(index, 1);
        state.selectedGame.learnerRetainment.keyFacts = keyFacts;
    };

    updateOtherKeyFacts = (state, action) => {
        state.selectedGame.learnerRetainment.otherKeyFacts = action.payload;
    }

    changeKeyMessage = (state, action) => {
        const {key, message} = action.payload;
        // console.log(key, message);
        const keyMessages = {...state.selectedGame.learnerRetainment.keyMessages};
        keyMessages[key] = message;
        // console.log(keyMessages);
        state.selectedGame.learnerRetainment.keyMessages = keyMessages;
    }
}

