
export default class LearningFocus {
    addRole = (state, action) => {
        const roles = [...state.selectedGame.learningFocus.roles]
        roles.push({name: '', tasks: []});
        state.selectedGame.learningFocus.roles = roles;
    }

    deleteRole = (state, action) => {
        const {index} = action.payload;
        const roles = [...state.selectedGame.learningFocus.roles]
        roles.splice(index, 1);
        state.selectedGame.learningFocus.roles = roles;
    }

    changeRoleName = (state, action) => {
        const {name, index} = action.payload;
        const roles = [...state.selectedGame.learningFocus.roles]
        roles[index].name = name;
        state.selectedGame.learningFocus.roles = roles;
    }

    updateRoleTasks = (state, action) => {
        const {tasks, index} = action.payload;
        const roles = [...state.selectedGame.learningFocus.roles];
        roles[index].tasks = tasks;
        state.selectedGame.learningFocus.roles = roles;
    }
}