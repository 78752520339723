// @mui
import {styled} from '@mui/material/styles';
import {Box, Card, Container, Stack, Typography} from '@mui/material';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import {LoginForm} from '../../sections/auth/login';
import Logo from "../../components/Logo";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {/*{smUp && (*/}
          {/*  <Typography variant="body2" sx={{ mt: { md: -2 } }}>*/}
          {/*    Already have an account?{' '}*/}
          {/*    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>*/}
          {/*      Login*/}
          {/*    </Link>*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </HeaderStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Bright Game
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <LoginForm />

            {/* {!smUp && ( */}
            {/*  <Typography variant="body2" align="center" sx={{ mt: 3 }}> */}
            {/*    Don’t have an account?{' '} */}
            {/*    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}> */}
            {/*      Get started */}
            {/*    </Link> */}
            {/*  </Typography> */}
            {/* )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
