import {Autocomplete, TextField} from "@mui/material";


export default function DropdownSelector({ sx, value, optionList, handleDropdownChange, objectKey, height, placeholder = '', error = false }) {
    return <Autocomplete
        sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            ...sx}}
        value={value}
        onChange={(event, newValue) => handleDropdownChange(objectKey, newValue)}
        id={objectKey}
        options={optionList.map((option) => option)}
        renderInput={(params) => <TextField
            multiline
            error={error}
            placeholder={placeholder}
            {...params}
            sx={{
                "& .MuiInputBase-input": {
                    overflow: "hidden !important",
                    textOverflow: "ellipsis !important",
                    height: `${height} !important`
                }
            }} />}
    />
}