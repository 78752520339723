import { useQuery } from '@tanstack/react-query';
import useAuth from './useAuth';
import { QueryKeys } from 'src/constants/queries';

const useGetGroup = (groupId) => {
  const { getGroup: fetchGroup  } = useAuth();

  const getGroup = useQuery([QueryKeys.Group, groupId], () =>
    fetchGroup(groupId).then((result) => result),
      {
        refetchOnWindowFocus: false,
      }
    );

  return getGroup;
};

export default useGetGroup;
