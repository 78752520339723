export function deepCompare(obj1, obj2) {
    function compare(obj1, obj2, path = "", changes = []) {
        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            if (obj1 !== obj2) {
                changes.push({
                    path: path || 'root',
                    oldValue: obj1,
                    newValue: obj2
                });
            }
            return;
        }

        // Compare keys from obj1 to obj2
        for (let key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                if (!obj2.hasOwnProperty(key)) {
                    changes.push({
                        path: path ? `${path}.${key}` : key,
                        oldValue: obj1[key],
                        newValue: ""
                    });
                } else {
                    compare(obj1[key], obj2[key], path ? `${path}.${key}` : key, changes);
                }
            }
        }

        // Compare keys from obj2 to obj1 for any extra keys
        for (let key in obj2) {
            if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
                changes.push({
                    path: path ? `${path}.${key}` : key,
                    oldValue: "",
                    newValue: obj2[key]
                });
            }
        }
        // eslint-disable-next-line consistent-return
        return changes;
    }

    return compare(obj1, obj2);
}

