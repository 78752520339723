import React, { createContext, useState, useContext } from 'react';

const UserAccountTypeContext = createContext();

export const UserAccountTypeProvider = ({ children }) => {
  const [selectedAccountType, setSelectedAccountType] = useState('player');

  const setAccountType = (accountType) => {
    setSelectedAccountType(accountType);
  };

  return (
    <UserAccountTypeContext.Provider value={{ selectedAccountType, setAccountType }}>
      {children}
    </UserAccountTypeContext.Provider>
  );
};

export const useUserAccountType = () => {
  const context = useContext(UserAccountTypeContext);
  if (!context) {
    throw new Error('useUserAccountType must be used within a UserAccountTypeProvider');
  }
  return context;
};