import {generateUniqueId} from '../../../../../helper/id'

export default class ProcessStages {

    changeProcessStagesKeyValue = (state, action) => {
        const {key, value} = action.payload;
        state.selectedGame.processStages[key] = value;
    }

    addKeyword = (state, action) => {
        const {value} = action.payload;
        const keywords = [...state.selectedGame.processStages.keywords];
        keywords.push(value);
        state.selectedGame.processStages.keywords = keywords;
    }

    acknowledgeWarning = (state) => {
        state.selectedGame.processStages.warningAcknowledged = true;
    }

    deleteKeyword = (state, action) => {
        const {index} = action.payload;
        const keywords = [...state.selectedGame.processStages.keywords];
        keywords.splice(index, 1);
        state.selectedGame.processStages.keywords = keywords;
    }


    addStage = (state, action) => {;
        const stages = [...state.selectedGame.processStages.stages];
        stages.push({
            id: generateUniqueId(),
            title: '',
            summary: '',
            roles: [],
            // additionalRoles: [],
        });
        state.selectedGame.processStages.stages = stages;
        if (stages.length >= 5) {
            state.selectedGame.processStages.minimumReached = true;
        }
    }

    deleteStage = (state, action) => {
        const {id} = action.payload;
        const stages = [...state.selectedGame.processStages.stages];
        const index = stages.findIndex(object => object.id === id);
        stages.splice(index, 1);
        state.selectedGame.processStages.stages = stages;
        if (stages.length < 5) {
            state.selectedGame.processStages.minimumReached = false;
        }
    }

    updateStageKeyValue = (state, action) => {
        const {id, key, value} = action.payload;
        const stages = [...state.selectedGame.processStages.stages];
        const index = stages.findIndex(object => object.id === id);
        stages[index][key] = value;
        state.selectedGame.processStages.stages = stages;
        state.selectedGame.processStages.selectedField = key + id;
    }

    updateStagesActionTitles = (state, action) => {
        const {stageEditIndex, editListKey, textEditIndex, text} = action.payload;
        const newStage = {...state.selectedGame.processStages.stages[stageEditIndex]}
        newStage[editListKey][textEditIndex] = text;
        state.selectedGame.processStages.stages[stageEditIndex] = newStage;
    }

    deleteStagesActionTitles = (state, action) => {
        const {stageEditIndex, editListKey, textEditIndex} = action.payload;
        const newStage = {...state.selectedGame.processStages.stages[stageEditIndex]}
        const newArray = [...newStage[editListKey]];
        if (textEditIndex !== -1) {
            newArray.splice(textEditIndex, 1)
        }
        newStage[editListKey] = newArray;
        state.selectedGame.processStages.stages[stageEditIndex] = newStage;
    }

    addStageActionTitles = (state, action) => {
        const {stageIndex, newEditListKey, text} = action.payload;
        const newStage = {...state.selectedGame.processStages.stages[stageIndex]}
        newStage[newEditListKey].push(text);
        state.selectedGame.processStages.stages[stageIndex] = newStage;
    }
}