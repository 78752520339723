import React from 'react';
import { Box, Container, Stack, Typography, LinearProgress } from '@mui/material';
import Page from 'src/components/Page';
import { useParams } from "react-router-dom";

import useGetGroup from 'src/hooks/useGetGroup';

export default function LearnerViewGroup() {
  const { id } = useParams();
  const getGroup = useGetGroup(id);
  const group = getGroup.data;

  if (getGroup.isLoading || false) {
    return (<LinearProgress />)
  }

  return (
    <Page title={group.groupName} sx={{ backgroundImage: 'linear-gradient(#FFFFFF, #E4F5FD)' }}>
      <Container maxWidth={'lg'} sx={{ minHeight: '100vh' }}>
        <Stack sx={{ mt: 2 }} justifyContent="space-between" direction="row" spacing={2}>
          <Typography variant={'h3'} gutterBottom>
            Group - {group.groupName}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', mt: 3 }}>
          </Box>
        </Box>
      </Container>
    </Page>
  );

}