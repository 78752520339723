// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    home: path(ROOTS_DASHBOARD, '/home'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    editProfile: path(ROOTS_DASHBOARD, '/edit-profile'),
    gameCreator: path(ROOTS_DASHBOARD, '/game-creator'),
    gallery: path(ROOTS_DASHBOARD, '/gallery'),
    myGames: path(ROOTS_DASHBOARD, '/my-games'),
    shareGame: path(ROOTS_DASHBOARD, '/share-token'),
    viewGame: path(ROOTS_DASHBOARD, '/view-token'),
    myGamesReports: path(ROOTS_DASHBOARD, '/my-games/my-games-reports'),
    collaborations: path(ROOTS_DASHBOARD, '/collaborations'),
    myGroups: path(ROOTS_DASHBOARD, '/my-groups'),
    viewGroup: path(ROOTS_DASHBOARD, '/my-groups/view-group'),
    account: path(ROOTS_DASHBOARD, '/account'),
    creatorResults: path(ROOTS_DASHBOARD, '/creator-results'),
};