import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import {
    Firestore,
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
    limit
} from 'firebase/firestore';
import { getApp, initializeApp } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    signInAnonymously
} from 'firebase/auth';


import {getDownloadURL, getStorage, listAll, ref, uploadBytes} from "firebase/storage";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {FIREBASE_API} from '../config';
import games from "../redux/slices/games";
import {getCurrentUserSubscriptions, getPrice, getProduct, getStripePayments} from "@stripe/firestore-stripe-payments";
import { accountLicenseTypes } from 'src/constants/accountOptions';
import { generateUsername } from 'unique-username-generator';
import axios from 'axios';
import { deepCompare } from 'src/utils/compare';


// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const FIRESTORE = getFirestore(firebaseApp);

const FUNCTIONS = getFunctions(firebaseApp, "europe-west2");
// const DATABASE = getDatabase(firebaseApp);
const STORAGE = getStorage(firebaseApp);

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    isOnboarded: false,
    firestoreUser: null,
    products: [],
    activeSubscription: null,
    userGamesList: [],
    userGamesCollaboratingOnList: [],
    userLicenseFeatures: {},
    creatorGroups: [],
    memberGroups: [],
    isSeatedUser: false,
    licenseOwnerID: null //the user ID of the owner of the license. Will be this users ID if they are the owner of the license
};

const reducer = (state, action) => {
    if (action.type === 'INITIALISE') {
        const { isAuthenticated, firestoreUser, activeSubscription, creatorGroups, memberGroups } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            activeSubscription,
            firestoreUser,
            creatorGroups,
            memberGroups,
            licenseOwnerID: firestoreUser.uid
        };
    }
    if (action.type === 'UNAUTHENTICATED') {
        const {isAuthenticated, firestoreUser} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            firestoreUser
        };
    }
    if (action.type === 'UPDATEUSER') {
        const {firestoreUser} = action.payload;
        return {
            ...state,
            firestoreUser,
        };
    }
    if (action.type === 'UPDATEGAMELIST') {
        const {userGamesList} = action.payload;
        return {
            ...state,
            userGamesList,
        };
    }
    if (action.type === 'UPDATEUSERCOLLABINGONGAMES') {
        const { userGamesCollaboratingOnList } = action.payload;
        return {
            ...state,
            userGamesCollaboratingOnList,
        };
    }
    if (action.type === 'UPDATELICENSEFEATUREDETAILS') { //update license feature details
        const {userLicenseFeatures} = action.payload;
        return {
            ...state,
            userLicenseFeatures
        }
    }
    if (action.type === 'SETUSERISSEATED') { //information on the user license type
        const { isSeatedUser, licenseOwnerID } = action.payload;
        return {
            ...state,
            isSeatedUser,
            licenseOwnerID 
        }
    }
    return state;
};

const AuthContext = createContext({
    ...initialState,
    method: 'firebase',
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(
        () =>
            onAuthStateChanged(AUTH, async (user) => {
                if (user) {
                    const userRef = doc(FIRESTORE, 'users', user.uid);
                    const docSnap = await getDoc(userRef);
                    if (docSnap.exists()) {
                        const firestoreUser = {uid: user.uid, ...user, ...docSnap.data()}
                        const activeSubscription = await checkForActiveSubscription(user.uid);
                        const creatorGroups = await GetMyGroups(user.uid);
                        const memberGroups = await GetGroupsUserIsAMemberOf(user.uid);
                        await getUserGamesList(user.uid);
                        await getGamesUserCollaboratingOn(user.uid);
                        dispatch({
                            type: 'INITIALISE',
                            payload: { isAuthenticated: true, firestoreUser, activeSubscription, creatorGroups, memberGroups },
                        });
                    } else {
                        dispatch({
                            type: 'UNAUTHENTICATED',
                            payload: {isAuthenticated: false, firestoreUser: null},
                        });
                        await logout();
                    }
                } else {
                    dispatch({
                        type: 'UNAUTHENTICATED',
                        payload: {isAuthenticated: false, firestoreUser: null},
                    });
                    await logout();
                }
            }),
        [dispatch]
    );


    const checkForActiveSubscription = async (licenseOwnerID) => {
        try {
        const app = getApp();
        const payments = getStripePayments(app, {
            productsCollection: "products",
            customersCollection: "users",
        });
            let myUserID = licenseOwnerID;
            const subscription = await checkSubscriptionStatus(licenseOwnerID);

            if (subscription !== null) {
                const {
                    current_period_end,
                    cancel_at,
                    cancel_at_period_end
                } = subscription;
                const price = await getPrice(payments, subscription.items[0].plan.product, subscription.items[0].plan.id);
                const product = await getProduct(payments, subscription.items[0].plan.product);
                await GetLicenseTierDetails(licenseOwnerID, myUserID);
                dispatch({
                    type: 'SETUSERISSEATED',
                    payload: { isSeatedUser: false, licenseOwnerID },
                });
                return {
                    ...subscription,
                    renewalDate: current_period_end,
                    price: price,
                    name: product.name,
                    selectedProduct: product,
                    cancel_at,
                    cancel_at_period_end
                }
            }

            const licenseSeatsCollection = collection(FIRESTORE, "licenseSeats");
            const seatsQuery = query(licenseSeatsCollection, where('seatedUserID.id', '==', licenseOwnerID), limit(1));
            const docSnapShot = await getDocs(seatsQuery);

            if (docSnapShot.size > 0) {
                console.log("not 0");
                console.log(docSnapShot.docs[0].data().licenseOwner);
                dispatch({
                    type: 'SETUSERISSEATED',
                    payload: { isSeatedUser: true, licenseOwnerID: docSnapShot.docs[0].data().licenseOwner},
                });
                return await checkForActiveSubscription(docSnapShot.docs[0].data().licenseOwner);
            }


        return null;
    }catch(error){
        console.error(error);
        alert(error);
        return null;
    }
    }

    const getLicenseIDs = async (licenseOwnerID) => {
        try {
            const licenseSeatsCollection = collection(FIRESTORE, "licenseSeats");
            const seatsQuery = query(licenseSeatsCollection, where('licenseOwner', '==', licenseOwnerID)); 
            const docSnapShot = await getDocs(seatsQuery);

            let ids = [];
            docSnapShot.docs.forEach(doc => {
                ids.push(doc.data().seatedUserID.id);
            })

            return ids;

        } catch (e) {
            console.log("Error getting license seats: " + e);
        }
    }

    const AssignALicenseSeat = async (licenseOwnerID, seatsToAssign) => {
        console.log("Here");
        try {
            const licenseSeatsCollection = collection(FIRESTORE, "licenseSeats");
            if (state.userLicenseFeatures.licenseSeats.remainingSeats >= seatsToAssign.length - 100) {
                let promises = new Array();
                seatsToAssign.forEach(seat => {
                    const data = {
                        licenseOwner: licenseOwnerID,
                        seatedUserID: seat.id,
                        status: 'accepted'
                    }
                    promises.push(addDoc(licenseSeatsCollection, data));
                })
                await Promise.all(promises);
            } else {
                alert("You do not have enough license seats remaining.");
            }
        } catch (e) {
            console.log("Error setting license seats: " + e);
        }
    }

    
    const GetLicenseTierDetails = async (licenseOwnerID = state.licenseOwnerID, myUserID = state.firestoreUser.uid) => {
        const url = 'https://europe-west2-brightgame-80a0e.cloudfunctions.net/GetLicenseTierDetails';

        console.log(licenseOwnerID);
        console.log(myUserID);

        const data = {
            'licenseOwnerUserID': licenseOwnerID,
            'myUserID': myUserID
        };
        try {
            const response = await axios.post(url, data);
            dispatch({
                type: 'UPDATELICENSEFEATUREDETAILS',
                payload: { userLicenseFeatures: response.data },
            });
        } catch (err) {
            console.log("err: " + err);
            return null;
        }
    }

    const checkSubscriptionStatus = async (userId) => {
        try {
            //const userRef = doc(, userId);
            const currentTime = Timestamp.now();
            // const periodEnd = doc.data().current_period_end.toDate();
            // const canceled_at = doc.data().canceled_at.toDate();

            const subscriptionsCollection = collection(FIRESTORE, 'users/' + userId + '/subscriptions')
            const subsQuery = query(subscriptionsCollection, where('status', '==', 'active'), where('current_period_end', '>=', currentTime));
            const subscriptionsSnapshot = await getDocs(subsQuery);
            if (subscriptionsSnapshot.size > 0) {
                return subscriptionsSnapshot.docs[0].data();
            }

            return null;

        } catch (error) {
            console.error('Error checking subscriptions status:', error);
        }
    };


    const getUserPaymentInfo = async (userId) => {
        try {
            const getUserPaymentCloudFunction = httpsCallable(FUNCTIONS, 'getUserPaymentInfo');
            const result = await getUserPaymentCloudFunction({userId: userId})
            return result.data
        } catch (e) {
            console.log(e);
            return {}
        }
    }

    const createUserPortalLink = async () => {
        try {
            const getUserPaymentCloudFunction = httpsCallable(FUNCTIONS, 'ext-firestore-stripe-payments-createPortalLink');
            const result = await getUserPaymentCloudFunction({returnUrl: window.location.origin + '/dashboard/account'})
            return result.data
        } catch (e) {
            console.log(e);
            return {}
        }
    }

    const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password).catch((error) => {
        alert(error);
    });

    const loginAnonymously = () => signInAnonymously(AUTH).catch((error) => {
        alert(error);
    });

    const register = (email, password, accountType, accountTypeIndex, accountSubType, accountSubTypeIndex, dateOfBirth, chosenPlanId) =>
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
            const userRef = doc(collection(FIRESTORE, 'users'), res.user?.uid);

            await setDoc(userRef, {
                uid: res.user?.uid,
                email,
                accountType,
                accountTypeIndex,
                accountSubType,
                accountSubTypeIndex,
                dateOfBirth,
                chosenPlanId,
                onboarded: false,
                licenseType: accountLicenseTypes.licenseOwner,
            });
        });

    const registerCreatorNoLicense = (email, password, accountType, accountTypeIndex, accountSubType, accountSubTypeIndex, dateOfBirth, chosenPlanId, firstName, lastName, organisation) =>
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
            const userRef = doc(collection(FIRESTORE, 'users'), res.user?.uid);

            await setDoc(userRef, {
                uid: res.user?.uid,
                email,
                accountType,
                accountTypeIndex,
                accountSubType,
                accountSubTypeIndex,
                dateOfBirth,
                licenseType: accountLicenseTypes.creator,
                onboarded: true,
                onbaordStep: 1,
                username: generateUsername()
            });
        });

        const registerAllAccess = (email, password, accountType, accountTypeIndex, accountSubType, accountSubTypeIndex, dateOfBirth, chosenPlanId) =>
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
            const userRef = doc(collection(FIRESTORE, 'users'), res.user?.uid);
            //const subColRef = collection(userRef, 'subscriptions');//, ['subscriptions', 'sub_adminDefaultSub']);
            //const subRef = doc(subColRef, 'sub_adminDefaultSub');//, ['subscriptions', 'sub_adminDefaultSub']);

            await setDoc(userRef, {
                uid: res.user?.uid,
                email,
                accountType,
                accountTypeIndex,
                accountSubType,
                accountSubTypeIndex,
                dateOfBirth,
                onboarded: true,
                onbaordStep: 1,
                organisation: "SkillsPlay All Access",
                licenseType: accountLicenseTypes.allAccess,
                username: 'allAccess_' + generateUsername()
            });

            if (firstName) {
                updateUserData.firstName = firstName;
            }
    
            if (lastName) {
                updateUserData.lastName = lastName;
            }
    
            if (organisation) {
                updateUserData.organisation = organisation;
            }

            // await setDoc(subRef, {
            //     status: 'active'
            // });
        });

    const registerLearner = async (email, password, accountType, accountTypeIndex, accountSubType, accountSubTypeIndex, dateOfBirth, chosenPlanId, firstName, lastName, organisation) =>
    {
        const res = await createUserWithEmailAndPassword(AUTH, email, password);

        const userRef = doc(collection(FIRESTORE, 'users'), res.user?.uid);
        //const subColRef = collection(userRef, 'subscriptions');//, ['subscriptions', 'sub_adminDefaultSub']);
        //const subRef = doc(subColRef, 'sub_adminDefaultSub');//, ['subscriptions', 'sub_adminDefaultSub']);

        const updateUserData = {
            uid: res.user?.uid,
            email,
            accountType,
            accountTypeIndex,
            accountSubType,
            accountSubTypeIndex,
            dateOfBirth,
            onboarded: true,
            onbaordStep: 1,
            licenseType: accountLicenseTypes.learner,
            username: generateUsername()
        };

        if (firstName) {
            updateUserData.firstName = firstName;
        }

        if (lastName) {
            updateUserData.lastName = lastName;
        }

        if (organisation) {
            updateUserData.organisation = organisation;
        }

        console.log("updating user", updateUserData);

        await setDoc(userRef, updateUserData);

        return updateUserData;
    };

    const logout = () => signOut(AUTH);

    const updateUserProfileData = async (userData) => {
        try {
            const userRef = doc(FIRESTORE, 'users', state.firestoreUser?.uid);
            await updateDoc(userRef, {
                ...userData
            });
            state.firestoreUser = {...state.firestoreUser, ...userData};
            dispatch({
                type: 'UPDATEUSER',
                payload: {isAuthenticated: true, firestoreUser: {...state.firestoreUser, ...userData}},
            });
            return true
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    const getPublicProfile = async (userId) => {
        try {
            const gameDoc = doc(FIRESTORE, 'users', userId);
            const userSnapshot = await getDoc(gameDoc);
            if (userSnapshot.exists()) {
                const {
                    profilePicture,
                    firstName,
                    lastName,
                    whyCollab,
                    whyDesign,
                    profileVisibility,
                    externalWebsite,
                    job,
                    organisation,
                    username,
                    email
                } = userSnapshot.data();
                return {
                    profilePicture,
                    firstName,
                    lastName,
                    whyCollab,
                    whyDesign,
                    profileVisibility,
                    externalWebsite,
                    job,
                    organisation,
                    username,
                    email
                }
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    // Things with an image: logo, background, scenario images
    const uploadCloudImages = async (files, path) => {
        try {
            const promises = files.map(async (file) => {
                // console.log("FILE")
                // console.log(file)
                const fileRef = ref(STORAGE, `${path}/${file.name}`);
                const snapshot = await uploadBytes(fileRef, file, {
                    contentType: 'image/jpeg'
                });
                return getDownloadURL(snapshot.ref);
            })
            return await Promise.all(promises)
        } catch (e) {
            console.log("error at upload cloud images", e)
            return []
        }
    }

        // Uploads an image to the shared asset library used by everyone on the license in the game creator
    const UploadImagesToLicenseAssetLibrary = async (files) => {
            try {
                const promises = files.map(async (file) => {
                    // console.log("FILE")
                    // console.log(file)
                    //const fileRef = ref(STORAGE, `user-game-creator-assets/test-folder/uncategorised/${file.name}`);
                    const fileRef = ref(STORAGE, `user-game-creator-assets/${state.licenseOwnerID}/uncategorised/${file.name}`);
                    const snapshot = await uploadBytes(fileRef, file, {
                        contentType: 'image/jpeg'
                    });
                    return getDownloadURL(snapshot.ref);
                })
                return await Promise.all(promises)
            } catch (e) {
                console.log("error uploading images to the license library", e)
                return e.message;
            }
        }

    const uploadLogoToStorage = async (file) => {
        try {
            const fileRef = ref(STORAGE, `logos/${file.name}`);
            const snapshot = await uploadBytes(fileRef, file, {
                contentType: 'image/jpeg'
            });
            const url = await getDownloadURL(snapshot.ref);
            return url;
        } catch (e) {
            console.log("error at upload cloud images", e)
            return []
        }
    }

    const callFirebaseFunction = async (functionName, params) => {
        let result = false
        try {
            const firebaseFunc = httpsCallable(FUNCTIONS, functionName);
            return params ? (await firebaseFunc(params)).data : (await firebaseFunc()).data;
        } catch (e) {
            console.log(e);
        }
        return result; 
    }

    const copyGame = async (gameID) => callFirebaseFunction('copyGame', { gameID });

    const updateGame = async ({ gameID, gameData }) => callFirebaseFunction('updateGame', { gameID, gameData });

    const assignLicenseSeats = async (seatsToAssign) => callFirebaseFunction('assignLicenseSeats', { seatsToAssign });

    const getLicenseSeats = async () => callFirebaseFunction('getLicenseSeats');

    const removeSeatFromLicense = async (seatedUserID) => callFirebaseFunction('removeSeatFromLicense', { seatedUserID });

    const getUserPreferences = async () => callFirebaseFunction('getUserPreferences');

    const updateUserPreferences = async (preferences) => callFirebaseFunction('updateUserPreferences', preferences);

    const getGroup = async (groupID) => callFirebaseFunction('getGroup', { groupID });

    const createGroup = async (groupName) => callFirebaseFunction('createGroup', { groupName });

    const deleteGroup = async (groupID) => callFirebaseFunction('deleteGroup', { groupID });

    const getGroups = async (search) => callFirebaseFunction('getGroups', { search });

    const getGroupSessions = async (groupID) => callFirebaseFunction('getGroupSessions', { groupID });

    const getGameSessionsForPlayer = async () => callFirebaseFunction('getGameSessionsForPlayer');

    const inviteUsersToGroup = async (groupId, users) =>
        callFirebaseFunction('inviteUsersToGroup', { 
            groupID: groupId,
            users: users || []
        });

    const resendGroupInvite = async (groupID, memberID, memberEmailAddress) => callFirebaseFunction('resendGroupInvite', { groupID, memberID, memberEmailAddress });

    const respondToGroupInvite = async (groupID, status) => callFirebaseFunction('respondToGroupInvite', { groupID, status });

    const withdrawGroupInvite = async (groupID, memberID) => callFirebaseFunction('withdrawGroupInvite', { groupID, memberID });

    const updateGroupInviteUserId = async (groupMembershipID, invitedUserID) =>
        callFirebaseFunction('updateGroupInviteUserId', { groupMembershipID, invitedUserID });

    const removeGroupMember = async (groupID, memberID) => callFirebaseFunction('removeGroupMember', { groupID, memberID });

    const getAppActivity = async () => callFirebaseFunction('getAppActivity');

    const getCollaborators = async (search) => callFirebaseFunction('getCollaborators', { search, limit: 10 });

    const searchGamesToCollaborate = async (search) => callFirebaseFunction('searchGamesToCollaborate', { search, limit: 10 });

    const getMyGames = async (search, limit = 50) => callFirebaseFunction('getMyGames', { search, limit });

    const getCollaboration = async (collaborationId) => callFirebaseFunction('getCollaboration', { id: collaborationId });

    const getCollaborations = async (search) => callFirebaseFunction('getCollaborations', { search });

    const getCollaborationInvites = async (search) => callFirebaseFunction('getCollaborationInvites', { search });

    const getGroupMemberships = async () => callFirebaseFunction('getGroupMemberships');

    const inviteCollaboratorsToGame = async (gameId, inviterUserId, collaboratorUserIds, emailAddresses, role) =>
        callFirebaseFunction("createCollaborations", {
            gameId,
            inviterUserId,
            collaboratorUserIds,
            emailAddresses,
            role
        });
    
    const resendCollaborationRequest = async (collaborationId) =>
        callFirebaseFunction("resendCollaborationRequest", { collaborationId });

    const updateCollaborationStatus = async (collaborationId, status) =>
        callFirebaseFunction("updateCollaborationStatus", {
            collaborationId,
            status
        });

    const createGameEditorComment = async (gameID, activeOuterSection, activeInnerSection, comment) => callFirebaseFunction('createGameEditorComment', { gameID, activeOuterSection, activeInnerSection, comment });

    const getGameEditorComments = async (gameID, activeOuterSection, activeInnerSection) => callFirebaseFunction('getGameEditorComments', { gameID, activeOuterSection, activeInnerSection });

    const saveGameVersion = async (gameId, changes) => callFirebaseFunction('saveGameVersion', { id: gameId, changes });

    const getGameVersions = async (gameId) => callFirebaseFunction('getGameVersions', { id: gameId });

    const restoreGameVersion = async (versionID) => callFirebaseFunction('restoreGameVersion', { versionID });

    const canEditGame = async (gameID) => callFirebaseFunction('canEditGame', { gameID });

    const canEditGroup = async (groupID) => callFirebaseFunction('canEditGroup', { groupID });

    const createGameSession = async (sessionData) => callFirebaseFunction('OS_CreateNewSession', sessionData);

    const getGameChanges = async (gameId, userId, gameUpdate) => {
        let changes = [];
        const gameRef = doc(collection(FIRESTORE, 'games'), gameId);
        const gameDocSnapshot = await getDoc(gameRef);
        if (gameDocSnapshot.exists()) {
            changes = deepCompare(gameDocSnapshot.data(), gameUpdate)
                .filter(gc => !["activeOuterSection", "activeInnerSection"].includes(gc.path))
                .map(gc => ({
                    objectId: gameId,
                    objectType: "game",
                    activityType: "edit",
                    userId: userId,
                    data: {
                        oldValue: gc.oldValue,
                        newValue: gc.newValue,
                        path: gc.path
                    },
                    timestamp: Timestamp.now()
                }));
        }
        return changes;
    };

    const updateGameData = async (id, activeOuterSection, activeInnerSection, data) => {
        const gameRef = doc(collection(FIRESTORE, 'games'), id);

        // Need this for deep copy of data, probably a way to improve this
        const gameUpdate = JSON.parse(JSON.stringify(data));

        gameUpdate.activeOuterSection = activeOuterSection
        gameUpdate.activeInnerSection = activeInnerSection

        const gameDesignSection = {...gameUpdate.gameDesign}

        // if (!isString(gameDesignSection.logo)) {
        //     //const fireStoreLinks = await uploadCloudImages([gameDesignSection.logo], `games/${id}/logo`)
        //     gameUpdate.gameDesign.logo = gameDesignSection.logo
        // }
        // if (!isString(gameDesignSection.background)) {
        //     // const fireStoreLinks = await uploadCloudImages([gameDesignSection.background], `games/${id}/background`)
        //     gameUpdate.gameDesign.background = gameDesignSection.background
        // }

        // Potentially a better way of doing this without the nested loop, but need to check individual images as they could be changed in an edit
        // Loop through each scenario
        const scenarioImageUpdates = gameUpdate.scenarios.scenarios.map(async (scenario, outerIndex) => {
            // Loop through each image
            const images = [...gameUpdate.scenarios.scenarios[outerIndex].images]
            images.map(async (image, innerIndex) => {
                // Check if image is a file, i.e. hasn't yet been uploaded to firebase
                if (!isString(image)) {
                    const fireStoreLinks = await uploadCloudImages([image], `games/${id}/scenarios/${scenario.id}/images`)
                    gameUpdate.scenarios.scenarios[outerIndex].images[innerIndex] = fireStoreLinks[0]

                }
            })
        })

        const charactersImageUpdates = gameUpdate.characters.rolesList.map(async (role, outerIndex) => {
            const {images} = gameUpdate.characters.rolesList[outerIndex]
            if (images && images.length > 0 && !isString(images[0])) {
                gameUpdate.characters.rolesList[outerIndex].images = await uploadCloudImages(images, `games/${id}/roles/${role.id}/images`)
            }
        })

        const actionsImageUpdates = gameUpdate.actions.actionsList.map(async (action, outerIndex) => {
            const {images} = gameUpdate.actions.actionsList[outerIndex]
            if (images && images.length > 0 && !isString(images[0])) {
                gameUpdate.actions.actionsList[outerIndex].images = await uploadCloudImages(images, `games/${id}/actions/${action.id}/images`)
            }
        })

        const gameChanges = await getGameChanges(id, state.firestoreUser?.uid, gameUpdate);

        // await Promise.all(scenarioImageUpdates)
        // await Promise.all(charactersImageUpdates)
        // await Promise.all(actionsImageUpdates)
        await updateDoc(gameRef, gameUpdate);

        await saveGameVersion(id, gameChanges);

        await Promise.all(gameChanges.map(gc =>
            addDoc(collection(FIRESTORE, 'appActivity'), gc)
        ));

        await getUserGamesList()
    }

    const updateGameDetails = async (key, data, id, foundGameIndex) => {
        const gameUpdate = JSON.parse(JSON.stringify(data));
        gameUpdate[key] = data;
        const gameRef = doc(collection(FIRESTORE, 'games'), id);
        await updateDoc(gameRef, gameUpdate)
        const newGamesList = [...state.userGamesList];
        newGamesList[foundGameIndex].shareDetails = gameUpdate;
        dispatch({
            type: 'UPDATEGAMELIST',
            payload: {userGamesList: newGamesList},
        });
    }

    const UpdateGameAccessToPlay = async (data, id) => {
        console.log(data);
        const gameUpdate = JSON.parse(JSON.stringify(data));
        const gameRef = doc(collection(FIRESTORE, 'games'), id);
        await updateDoc(gameRef, gameUpdate);
        await getUserGamesList(user.uid);
        // const newGamesList = [...state.userGamesList];
        // newGamesList[foundGameIndex].shareDetails = gameUpdate;
        // dispatch({
        //     type: 'UPDATEGAMELIST',
        //     payload: {userGamesList: newGamesList},
        // });
    }


    const createGameData = async (data) => {
        try {
        const gameCol = collection(FIRESTORE, 'games');
            const gameUpdate = { ...data, uid: state.firestoreUser.uid, accessToPlay: 'privatePlay' };
            gameUpdate.activeStep = 1;
            const gameRef = await addDoc(gameCol, gameUpdate);

            const dataToSend = {
                gameID: gameRef.id
            }
            const response = await axios.post('https://europe-west2-brightgame-80a0e.cloudfunctions.net/CreateStaticToken', dataToSend);
            console.log(response.data);
            const dataToAdd = {
                staticToken: response.data
            }

            await setDoc(gameRef, dataToAdd, { merge: true });


            await getUserGamesList();
        return gameRef.id;
        } catch (err) {
            console.log("Error creating game: " + err);
            return null;
        }
    }

    const getUserGamesList = async (userId = state.firestoreUser.uid) => {
        const gameQuery = collection(FIRESTORE, 'games');
        const q = query(gameQuery, where("uid", "==", userId));
        const querySnapshot = await getDocs(q);
        const userGamesList = querySnapshot.docs.map((doc) => {
            const game = doc.data()
            game.id = doc.id
            if (game.archived === undefined && game.aboutTheGame && 'name' in game.aboutTheGame) game.aboutTheGame.name += ' (legacy)'
            return game
        }).filter(game => !game.archived)
        dispatch({
            type: 'UPDATEGAMELIST',
            payload: {userGamesList},
        });
    }

    const getGamesUserCollaboratingOn = async (userId = state.firestoreUser.uid) => {
        const gameQuery = collection(FIRESTORE, 'collaborations');
        const q = query(gameQuery, where('invitedUserId', '==', userId), where('status', '==', 'accepted'));
        const querySnapshot = await getDocs(q);
        const userGamesListPromises = querySnapshot.docs.map(async (doc) => {
            const game = await getSpecificGame(doc.data().gameId)
            if (game.archived === undefined && game.aboutTheGame && 'name' in game.aboutTheGame) game.aboutTheGame.name += ' (legacy)'
            return {
                game: game,
                role: doc.data().role,
                status: doc.data().status
            };
        });

        const userGamesList = await Promise.all(userGamesListPromises);
        const userGamesCollaboratingOnList = userGamesList.filter(game => !game.archived) || [];
        //console.log(userGamesCollaboratingOnList);
        dispatch({
            type: 'UPDATEUSERCOLLABINGONGAMES',
            payload: { userGamesCollaboratingOnList },
        });
    }

    //published games that do not belong to this user
    const getAllNonUserPublishedGames = async (userId = state.firestoreUser.uid) => {
        const gameQuery = collection(FIRESTORE, 'games');
        const q = query(gameQuery, where("uid", "!=", userId));
        const querySnapshot = await getDocs(q);
        const publishedGamesList = querySnapshot.docs.map((doc) => {
            const game = doc.data()
            game.id = doc.id
            if (game.archived === undefined && game.aboutTheGame && 'name' in game.aboutTheGame) game.aboutTheGame.name += ' (legacy)'
            return game
        }).filter(game => game.shareDetails?.published === 'published').filter(game => !game.archived)


        const publicPublishedGames = publishedGamesList.map(async (game) => {
            const publicProfile = await getPublicProfile(game.uid);
            return {publicProfile, ...game};
        })

        return await Promise.all(publicPublishedGames)

        return publishedGamesList;
    }

    const getSpecificGame = async (gameId) => {
        try {
            const gameDoc = doc(FIRESTORE, 'games', gameId);
            const gameSnapshot = await getDoc(gameDoc);
            if (gameSnapshot.exists()) {
                return {id: gameSnapshot.id, ...gameSnapshot.data()};
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    const GetUserRoleForGame = (gameID) => {

        if (state.userGamesList.some(game => game.id === gameID)) {
            return 'owner';
        }
        const collabRole = state.userGamesCollaboratingOnList.find(collab => collab.game.id === gameID);
        if (collabRole) {
            return collabRole.role;
        }

        return null;

    }

    const CanUseEditGame = (gameID) => {
        if (GetUserRoleForGame(gameID) == 'owner')
            return true;
        if (GetUserRoleForGame(gameID) == 'Editor')
            return true;

        return false;
    }

    const getAssetLibraryImages = async () => {
        // console.log('getting urls');
        const listRef = await ref(STORAGE, `user-game-creator-assets/${state.licenseOwnerID}/uncategorised`);
        return listAll(listRef)
            .then((res) => {
                const urlPromises = res.items.map(item => getDownloadURL(item));
                return Promise.all(urlPromises);
            }).catch((error) => {
                console.log("Error", error.message)
            });
    }

    const getImageUrlsFromStorage = async (referenceUrl) => {
        // console.log('getting urls');
        const listRef = await ref(STORAGE, referenceUrl)
        return listAll(listRef)
            .then((res) => {
                const urlPromises = res.items.map(item => getDownloadURL(item));
                return Promise.all(urlPromises);
            }).catch((error) => {
                console.log("Error", error.message)
            });
    }

    const getImageUrlFromStorage = async (referenceUrl) => {
        const listRef = await ref(STORAGE, referenceUrl)
        return getDownloadURL(listRef)
    }


    const deleteGame = async (id) => {
        const gameRef = doc(collection(FIRESTORE, 'games'), id);
        await updateDoc(gameRef, {
            'archived': true
        })
        await getUserGamesList();
    }

    // eslint-disable-next-line consistent-return
    const getGameLinkUrl = async () => {
        try {
            const constantsRef = doc(collection(FIRESTORE, 'appData'), 'constants');
            const document = await getDoc(constantsRef)
            const data = document.data()
            const {gameUrl} = data
            return gameUrl
        } catch (e) {
            console.log('error at getGameLinkUrl')
            console.log(e)
            return ''
        }
    }

    const getWelcomeVideoEmbedId = async () => {
        try {
            const constantsRef = doc(collection(FIRESTORE, 'appData'), 'constants');
            const document = await getDoc(constantsRef)
            const data = document.data()
            const {welcomeVideoEmbedId} = data
            return welcomeVideoEmbedId
        } catch (e) {
            console.log('error at getWelcomeVideoEmbedId')
            console.log(e)
            return ''
        }
    }

    const getDesignVideoEmbedId = async () => {
        try {
            const constantsRef = doc(collection(FIRESTORE, 'appData'), 'constants');
            const document = await getDoc(constantsRef)
            const data = document.data()
            const {designVideoEmbedId} = data
            return designVideoEmbedId
        } catch (e) {
            console.log('error at getDesignVideoEmbedId')
            console.log(e)
            return ''
        }
    }


    //STRIPE PRODUCTS
    const getSpecificProduct = async (productId) => {
        try {
            const productDoc = doc(FIRESTORE, 'products', productId);
            const productSnapshot = await getDoc(productDoc);
            if (productSnapshot.exists()) {
                return {id: productSnapshot.id, ...productSnapshot.data()};
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    const subscribeToPlan = async (priceId, successUrl, cancelUrl) => {
        const docRef = doc(FIRESTORE, "users", state.firestoreUser.uid);
        const colRef = collection(docRef, "checkout_sessions")
        const checkoutSnapshot = await addDoc(colRef, {
            price: priceId,
            allow_promotion_codes: true,
            success_url: successUrl,
            cancel_url: cancelUrl,
        });
        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(checkoutSnapshot, (snap) => {
            const {error, url} = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occurred: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        })
    }

    const GetRemainingGamesOnLicense = async() =>{
        const url = 'https://europe-west2-brightgame-80a0e.cloudfunctions.net/GetRemainingGamesOnLicense';
        const data = {'userID': state.firestoreUser.uid};
        try {
            const response = await axios.post(url, data);
            //console.log("GAMES: " + response.data);
            return response.data;
        } catch (err){
            console.log("err: " + err);
            return null;
        }
    }

    /// Takes a game link and retrives the information regarding play and access settings
    const ResolveGameLink = async (linkID) => {
        const docRef = doc(FIRESTORE, "gameLinks", linkID);
        return (await getDoc(docRef)).data();
    }

    const GetMyGroups = async (firestoreUserID) =>{
        try{
            //console.log(firestoreUserID);
            const groupCollection = collection(FIRESTORE, 'groups');
            const myGroupsQuery = await query(groupCollection, where('ownerID', '==', firestoreUserID));
            const myOtherGroupsQuery = await query(groupCollection, where('otherCreators.userID', 'array-contains', firestoreUserID));

            const myGroupsSnapshot = await getDocs(myGroupsQuery);
            const myOtherGroupsSnapshot = await getDocs(myOtherGroupsQuery);

            // const groupDoc = doc(FIRESTORE, "groups", firestoreUserID);
            // const myGroupsSnapshot = await getDoc(groupDoc);

            // const myOtherGroupsQuery =  await query(collection(FIRESTORE, 'groups'), where('otherCreators.userID', 'array-contains', firestoreUserID));
            // const myOtherGroupsSnapshot = await getDocs(myOtherGroupsQuery);

            let myGroups = new Array();

            myGroupsSnapshot.forEach((doc) => {
                // Access the document data using doc.data()
                const data = doc.data();
                myGroups.push(data);
            });
            myOtherGroupsSnapshot.forEach((doc) => {
                // Access the document data using doc.data()
                const data = doc.data();
                myGroups.push(data);
            });

            return myGroups;
        }
        catch(error){
            console.log("error getting my groups: " + error);
        }
    }

    const GetGroupsUserIsAMemberOf = async (firestoreUserID) => {
        try {
            //console.log(firestoreUserID);
            const groupCollection = collection(FIRESTORE, 'groups');
            const myGroupsQuery = await query(groupCollection, where('members', 'array-contains', firestoreUserID));

            const myGroupsSnapshot = await getDocs(myGroupsQuery);
            // const groupDoc = doc(FIRESTORE, "groups", firestoreUserID);
            // const myGroupsSnapshot = await getDoc(groupDoc);

            // const myOtherGroupsQuery =  await query(collection(FIRESTORE, 'groups'), where('otherCreators.userID', 'array-contains', firestoreUserID));
            // const myOtherGroupsSnapshot = await getDocs(myOtherGroupsQuery);

            let myGroups = new Array();

            myGroupsSnapshot.forEach((doc) => {
                // Access the document data using doc.data()
                //console.log(doc.data().groupID);
                const data = doc.data();
                myGroups.push(doc.data().groupID);
            });

            return myGroups;
        }
        catch (error) {
            console.log("error getting member groups: " + error);
        }
    }

    const GetGroupResult = async (groupID) =>{
        try{
            const resultsCollection = collection(FIRESTORE, 'gameResults');
            const resultsQuerySnapshot = await getDocs( await query(resultsCollection, where('groupID', '==', groupID)) );

            let results = new Array();

            resultsQuerySnapshot.forEach(doc => {
                results.push(doc.data());
            });

            //console.log(results);
            return results;
        }
        catch(error){
            console.log("error getting my groups: " + error);
        }
    }

    const GetResult = async (resultID) => {
        try {
            const docRef = doc(FIRESTORE, "gameResults", resultID);
            return (await getDoc(docRef)).data();
        } catch (error) {
            console.log("error getting session results: " + error);
        }
    }

    // LICENSE DETAILS TYPICALLY LOOKS LIKE THIS // **OUTDATED
    // {
    //     "Sharing": { //per license
    //         "canShareDirectAccessToGallery": false
    //     },
    //     "gameCustomisation": { //per license
    //         "canUseAnimatedCharacterCards": false,
    //         "canAddCustomBackgrounds": false,
    //         "canUseCustomEventAnimations": false,
    //         "canCreateCustomThemeColours": false,
    //         "canRequestCustomActionCardDesigns": false,
    //         "canAddLogo": false
    //     },
    //     "gameGalleryFunctions": { //per license
    //         "canCopyGames": false,
    //         "hasDedicatedLandingPage": false,
    //         "hasMarketAccess": false,
    //         "hasBrandedLandingPage": false
    //     },
    //     "licenseAndCollab": { //per license
    //         "hasApprovalAndVersioning": false
    //     },
    //     "specialGameFeatures": { //per license
    //         "canUseCardClusters": false,
    //         "canUseFreeAction": false
    //     },
    //     "groupsOnLicense": { //per license
    //         "maxGroups": 1,
    //         "currentGroups": 0,
    //         "remainingGroups": 1
    //     },
    //     "collaborationsWithOthers": { //per seat
    //         "maxCollaborationsOnOtherGames": 1,
    //         "currentCollaborationsOnOtherGames": 0,
    //         "remainingCollaborationsOnOtherGames": 1
    //     }
    // }

    return (
        <AuthContext.Provider
            value={
                /* eslint-disable-next-line react/jsx-no-constructed-context-values */
                {
                    ...state,
                    method: 'firebase',
                    user: {
                        uid: state?.user?.uid || state.firestoreUser?.uid,
                        email: state?.user?.email || state.firestoreUser?.email,
                        displayName: state?.user?.displayName || state.firestoreUser?.name || 'BrightGame',
                        role: state?.firestoreUser?.accountType,
                        organization: state?.firestoreUser?.organization || 'developer'
                    },

                    //user
                    login,
                    loginAnonymously,
                    logout,
                    updateUserProfileData,
                    createUserPortalLink,
                    getPublicProfile,
                    getUserPreferences,

                    //game data
                    updateGameData,
                    getGameData: getUserGamesList,
                    createGameData,
                    deleteGame,
                    getSpecificGame,
                    updateGame,
                    updateGameDetails,
                    UpdateGameAccessToPlay,
                    copyGame,
                    getGameLinkUrl,
                    getAllNonUserPublishedGames,

                    //game-creator
                    GetUserRoleForGame,
                    CanUseEditGame,
                    getAssetLibraryImages,
                    getImageUrlsFromStorage,
                    getImageUrlFromStorage,
                    uploadLogoToStorage,
                    uploadCloudImages,
                    UploadImagesToLicenseAssetLibrary,
                    getDesignVideoEmbedId,
                    getWelcomeVideoEmbedId,

                    //licensing
                    getLicenseSeats,
                    GetRemainingGamesOnLicense,
                    getLicenseIDs,
                    AssignALicenseSeat,
                    assignLicenseSeats,
                    removeSeatFromLicense,
                    GetLicenseTierDetails,

                    //registration
                    subscribeToPlan,
                    register,
                    registerAllAccess,
                    registerLearner,
                    getSpecificProduct,
                    registerCreatorNoLicense,

                    //collabs
                    getCollaboration,
                    getCollaborations,
                    getCollaborationInvites,
                    getCollaborators,
                    getMyGames,
                    searchGamesToCollaborate,
                    inviteCollaboratorsToGame,
                    resendCollaborationRequest,
                    updateCollaborationStatus,
                    getAppActivity,
                    getGameVersions,

                    //groups and launching
                    ResolveGameLink,
                    GetMyGroups,
                    GetGroupResult,
                    GetResult,
                    createGroup,
                    deleteGroup,
                    getGroup,
                    getGroups,
                    getGroupSessions,
                    getGameSessionsForPlayer,
                    getGroupMemberships,
                    inviteUsersToGroup,
                    resendGroupInvite,
                    updateGroupInviteUserId,
                    respondToGroupInvite,
                    withdrawGroupInvite,
                    updateUserPreferences,
                    removeGroupMember,
                    createGameEditorComment,
                    getGameEditorComments,
                    restoreGameVersion,
                    canEditGame,
                    canEditGroup,
                    createGameSession
                }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};


// async function sendAnalyticData(sessionid, timestamp, timeZone) {
//     const docRef = doc(collection(db, 'analyticData'), sessionid);
//     try {
//     await setDoc(docRef, {
//         sessionID: sessionid,
//         timeStamp: timestamp,
//         timeZone: timeZone,
//     });
//     } catch (error){
//         console.log(error);
//     }
// }

                //import { getFirestore, collection, getDocs } from 'https://www.gstatic.com/firebasejs/${FIREBASE_VERSION}/firebase-firestore-lite.js';
                // import { initializeApp } from "https://www.gstatic.com/firebasejs/10.6.0/firebase-app.js";
                // import { doc, setDoc, getFirestore, collection } from "https://www.gstatic.com/firebasejs/10.6.0/firebase-firestore-lite.js";


                // match /analyticData/{session}{
                //     allow read, write
                // }