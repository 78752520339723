import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import NavbarVertical from './navbar/NavbarVertical';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
}));

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {/*<DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />*/}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
