import { useQuery } from '@tanstack/react-query';
import useAuth from './useAuth';

const useGetMyGroupMemberships = (search) => {
  const { getGroupMemberships: fetchMemberships } = useAuth();

  const getGroupMemberships = useQuery(['group-memberships', search], () =>
    fetchMemberships().then((result) => result),
      {
        refetchOnWindowFocus: false,
      }
    );

  return { getGroupMemberships };
};

export default useGetMyGroupMemberships;
