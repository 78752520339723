// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{width: 1, height: 1}}/>;

const ICONS = {
    user: getIcon('ic_user'),
    hammer: getIcon('ic_hammer'),
    home: getIcon('ic_home'),
    collaborations: getIcon('ic_collaborations'),
    myGroups: getIcon('ic_mygroups'),
    myGames: getIcon('ic_mygames'),
    gallery: getIcon('ic_gallery'),
    account: getIcon('ic_account'),
};


const navConfig = [
    {
        subheader: '',
        items: [
            {
                title: 'Home',
                path: PATH_DASHBOARD.home,
                icon: ICONS.home,
                children: [
                    {title: 'My Profile', path: PATH_DASHBOARD.profile, },
                ]
            },
            {
                title: 'Gallery',
                path: PATH_DASHBOARD.gallery,
                icon: ICONS.gallery,
            },
            {
                title: 'Game Creator',
                path: PATH_DASHBOARD.gameCreator,
                icon: ICONS.hammer,
            },
            {
                title: 'My Games',
                path: PATH_DASHBOARD.myGames,
                icon: ICONS.myGames,
            },
            {
                title: 'Collaborations',
                path: PATH_DASHBOARD.collaborations,
                icon: ICONS.collaborations,
            },
            {
                title: 'My Groups',
                path: PATH_DASHBOARD.myGroups,
                icon: ICONS.myGroups,
            },
            // {
            //     title: 'My Results',
            //     path: PATH_DASHBOARD.creatorResults,
            //     icon: ICONS.account,
            // },
            {
                title: 'Account',
                path: PATH_DASHBOARD.account,
                icon: ICONS.account,
            },
        ],
    },
];

const learnerNavConfig = [
    {
        subheader: '',
        items: [
            {
                title: 'Home',
                path: PATH_DASHBOARD.home,
                icon: ICONS.home,
                children: [
                    {title: 'My Profile', path: PATH_DASHBOARD.profile, },
                ]
            },
            {
                title: 'Gallery',
                path: PATH_DASHBOARD.gallery,
                icon: ICONS.gallery,
            },
            // {
            //     title: 'Game Creator',
            //     path: PATH_DASHBOARD.gameCreator,
            //     icon: ICONS.hammer,
            // },
            // {
            //     title: 'My Games',
            //     path: PATH_DASHBOARD.myGames,
            //     icon: ICONS.myGames,
            // },
            // {
            //     title: 'Collaborations',
            //     path: PATH_DASHBOARD.collaborations,
            //     icon: ICONS.collaborations,
            // },
            // {
            //     title: 'My Groups',
            //     path: PATH_DASHBOARD.myGroups,
            //     icon: ICONS.myGroups,
            // },
            {
                title: 'Account',
                path: PATH_DASHBOARD.account,
                icon: ICONS.account,
            },
        ],
    },
];

export default navConfig;
export {learnerNavConfig};