export default class Network {
  startLoading = (state) => {
    state.isLoading = true;
  };

  stopLoading = (state) => {
    state.isLoading = false;
  };

  startDataRetrieval = (state) => {
    state.retrievingData = true;
  };

  stopDataRetrieval = (state, action) => {
    state.retrievingData = false;
    state.gamesList = action.payload;
  };

  onUpdateGameData = (state, action) => {
    const { activeOuterSection, activeInnerSection, updateGameData } = action.payload;
    updateGameData(state.selectedGame.id, activeOuterSection, activeInnerSection, state.selectedGame);
  };
}
