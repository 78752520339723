
exports.getAuthErrorMessage = (error) => {
    const {message} = error

    switch (message) {
        case "Firebase: Error (auth/network-request-failed).":
            return "Network Request Failed"
        case "Firebase: Error (auth/user-not-found).":
            return "Invalid Email or Password"
        case "Firebase: Error (auth/wrong-password).":
            return "Invalid Email or Password"
        default:
            return "An Unknown Error Occurred"
    }
}