// form
// @mui
import {Button, CircularProgress} from '@mui/material';
import useAuth from "../../../../hooks/useAuth";
import UpsellProHost from "./upsellProHost";
import UpsellProMax from "./upsellProMax";
import UpsellPartnerGold from "./upsellPartnerGold";
import UpsellPartnerSilver from "./upsellPartnerSilver";
import UpsellPartnerPlatinum from "./upsellPartnerPlatinum";
import {stripeProductIds} from "../../../../constants/stripeProductIds";
// hooks

// components
// ----------------------------------------------------------------------


export default function Upsell(props) {

    const {firestoreUser, createUserPortalLink, subscribeToPlan, TakeOneTimePayment} = useAuth();

    const {logout} = useAuth();

    const {loading, chosenProduct} = props;

    const showUpsell = () => {
        if (chosenProduct.name === 'Standard') {
            return <UpsellProHost/>
        } else if (chosenProduct.name === 'Pro Host') {
            return <UpsellProMax/>
        } else if (chosenProduct.name === 'Pro Max') {
            return <UpsellPartnerSilver/>
        } else if (chosenProduct.name === 'Partner Silver') {
            return <UpsellPartnerGold/>
        } else if (chosenProduct.name === 'Partner Gold') {
            return <UpsellPartnerPlatinum/>
        }  else {
            return <UpsellProHost/>
        }
    }


    return (
        <div style={{backgroundColor: '#c8e8e8', height: '100vh', width: '100vw'}}>
            <div style={{width: '100%', textAlign: 'right', height: '100px'}}>
                <Button
                    variant="contained"
                    onClick={() => logout()}
                    sx={{
                        whiteSpace: 'nowrap',
                        borderRadius: '30px',
                        backgroundColor: '#1F8E97',
                        marginRight: '10px',
                        marginTop: '10px'
                    }}
                >
                    Sign out
                </Button>
            </div>
            {loading ? <CircularProgress/> : showUpsell()}
        </div>
    );
}
