export default class SavedGames {
    onSuccessfulGameDelete = (state, action) => {
        const id = action.payload;
        const index = state.gamesList.findIndex(x => x.id === id);
        const games = [...state.gamesList]
        games.splice(index, 1);
        state.gamesList = games;
        state.isLoading = false;
    }

}