// form
// @mui
import {Box, Button, Card, CardContent, Container, Link, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "../../../../hooks/useAuth";
import React, {useState} from 'react';
import {stripeProductIds} from "../../../../constants/stripeProductIds";
import UpsellCard from "./upsellCard";
// hooks
// components
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({theme}) => ({
    backgroundColor: '#c8e8e8',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    maxHeight: '95vh',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(7, 7),
}));

const LeftSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    padding: theme.spacing(10, 40, 10, 50),
    borderRadius: '15px 0px 0px 15px'

}));

const RightSectionStyle = styled(Container)(({theme}) => ({
    backgroundColor: '#1f8e97',
    width: '100%',
    maxWidth: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 7, 2, 7),
    borderRadius: '0px 15px 15px 0px',
    alignItems: 'center'
}));

const CheckListItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'left',
    margin: '12px'
}));

export default function UpsellPartnerSilver(props) {


    const {firestoreUser, createUserPortalLink, subscribeToPlan} = useAuth();
    const [loading, setLoading] = useState(false);

    return (
        <UpsellCard
            leftContent={
                <Box sx={{pr: 6, pl: 6}}>
                    <Typography variant="h3" gutterBottom>
                        Why compromise…become
                        a partner!
                    </Typography>
                    <Typography variant="h5" paragraph color={'#0c4760'} fontWeight={'bold'}>
                        Get your own custom branded hosting
                        page and profile on our websites.
                    </Typography>
                    <Typography variant="body1" paragraph color={'#399ba3'}>
                        In addition <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>
                    benefit from single sign on and the
ability to build bigger collaborative design
teams.
                </span>
                    </Typography>
                    <Typography variant="body1" paragraph color={'#399ba3'}>
                        Benefit from <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>better discounts and support from
the SkillsPlay studio</span> and explore potential for <span
                        style={{fontWeight: 'bold', color: '#0c4760'}}>broader games libraries</span>with upto 100 projects!
                    </Typography>
                    <Typography variant="body2" paragraph color={'black'}>
                        Purchase a ‘Partner Silver’ annual subscription in advance
                        today and save <span style={{fontWeight: 'bold', color: '#399ba3'}}>10% off your first year</span>!
                    </Typography>
                </Box>
            }
            rightContent={
                <Card sx={{
                    backgroundColor: '#ffffff',
                    margin: '30px 70px 30px 70px',
                    height: '100%',
                    width: '375px',
                    textAlign: 'center',
                    color: 'black'
                }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Partner Silver
                        </Typography>
                        <Typography variant="h2" color={'#86cccb'}>
                            {/*{'£' + price.unit_amount / 100}*/}
                            £100
                        </Typography>
                        <Typography variant="p" gutterBottom sx={{marginBottom: '20px'}}>
                            per month
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            £1000 setup fee
                        </Typography>
                        <div style={{marginLeft: '35px', marginTop: '20px'}}>
                            <CheckListItem>
                                <CheckCircleIcon color="info"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    All Pro Max plan features
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="info"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    100 game design projects
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="info"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    Game design approval process
                                </Typography>
                            </CheckListItem>
                            <CheckListItem>
                                <CheckCircleIcon color="info"/>
                                <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                    Single sign on/10 user licences
                                </Typography>
                            </CheckListItem><
                            CheckListItem>
                            <CheckCircleIcon color="info"/>
                            <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                1200 maximum players
                            </Typography>
                        </CheckListItem><CheckListItem>
                            <CheckCircleIcon color="info"/>
                            <Typography variant="body1" component="span" sx={{marginLeft: 1}}>
                                100 maximum cohorts
                            </Typography>
                        </CheckListItem>
                        </div>
                        <Button variant="contained" sx={{
                            backgroundColor: '#1f8e97',
                            color: 'white',
                            marginTop: '20px',
                            marginBottom: '10px'
                        }}
                                onClick={async () => {
                                    setLoading(true)
                                    await subscribeToPlan(stripeProductIds.partnerSilver, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                                }}
                        >Buy Now</Button>
                        <br/>
                        <Link variant="subtitle2" underline="always" sx={{color: 'black', marginTop: '10px'}}
                              onClick={async () => {
                                  setLoading(true)
                                  await subscribeToPlan(stripeProductIds.proMax, window.location.origin + '/dashboard/Home', window.location.origin + '/dashboard/Home')
                              }}>
                            Continue to Pro Max
                        </Link>
                    </CardContent>
                </Card>
            }
            />
    )

}
