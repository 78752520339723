export default class Navigation {
    onBackStep = (state, action) => {
        const {activeOuterSection, activeInnerSection} = action.payload;
        state.selectedGame.activeOuterSection = activeOuterSection;
        state.selectedGame.activeInnerSection = activeInnerSection;
    }

    onNextStep = (state, action) => {
        const {activeOuterSection, activeInnerSection} = action.payload;
        state.selectedGame.activeOuterSection = activeOuterSection;
        state.selectedGame.activeInnerSection = activeInnerSection;
    }

    onGotoStep = (state, action) => {
        state.selectedGame.activeStep = action.payload;
    }

    setSelectedGame = (state, action) => {
        state.selectedGame = action.payload;
    }

    setCanEditSelectedGame = (state, action) => {
        state.canEditSelectedGame = action.payload;
    }
}