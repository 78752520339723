export default class RolesInvolved {
    addInvolvedRole = (state, action) => {
        const roles = [...state.selectedGame.rolesInvolved.roles];
        roles.push({name: '', stages: [], prefilled: false});
        state.selectedGame.rolesInvolved.roles = roles;
    }

    // updateRole = (state, action) => {
    //     const {name, stage} = action.payload;
    //     let rolesList = [...state.selectedGame.processStages.rolesList]
    //     let roleNamesList = [...state.selectedGame.processStages.roleNamesList]
    //     const roleIndex = rolesList.findIndex(existingRole => existingRole.name === name);
    //     const id = (Math.random() + 1).toString(36).substring(7);
    //
    //     if (roleIndex === -1) {
    //         roleNamesList.push(name)
    //         rolesList.push({
    //             id: id,
    //             name: name,
    //             characterImageUrl: '',
    //             realWorldLink: false,
    //             realName: '',
    //             realRole: '',
    //             email: '',
    //             stages: [stage]
    //         })
    //     } else {
    //         let roleToUpdate = rolesList[roleIndex]
    //         const {stages} = roleToUpdate
    //         roleToUpdate.stages = [...stages, stage]
    //         rolesList[roleIndex] = roleToUpdate;
    //     }
    //
    //     state.selectedGame.processStages.rolesList = rolesList
    //     state.selectedGame.processStages.roleNamesList = roleNamesList
    // }
    //
    // deleteRole = (stage, action) => {
    //     // const {}
    // }

    changeInvolvedRoleName = (state, action) => {
        const {index, name} = action.payload;
        const roles = [...state.selectedGame.rolesInvolved.roles];
        roles[index].name = name;
        state.selectedGame.rolesInvolved.roles = roles;
    }
    
    deleteInvolvedRole = (state, action) => {
        const {index} = action.payload;
        const roles = [...state.selectedGame.rolesInvolved.roles];
        roles.splice(index, 1);
        state.selectedGame.rolesInvolved.roles = roles;
    }
    
    updateInvolvedRoleStages = (state, action) => {
        const {index, stages} = action.payload;
        const roles = [...state.selectedGame.rolesInvolved.roles];
        roles[index].stages = stages;
        state.selectedGame.rolesInvolved.roles = roles;
    }

    updateInvolvedRolePrefilled = (state, action) => {
        const {rolesArray} = action.payload;
        state.selectedGame.rolesInvolved.roles = rolesArray;
    }
}