import {createSlice} from '@reduxjs/toolkit';
import AboutTheGame from './helper/steps/aboutTheGame'
import LearningObjectives from './helper/steps/learningObjectives'
import LearningFocus from './helper/steps/learningFocus'
import RolesInvolved from "./helper/steps/rolesInvolved";
import LearningRetainment from './helper/steps/learningRetainment'
import Scenarios from "./helper/steps/scenarios";
import ProcessStages from "./helper/steps/processStages";

import Navigation from './helper/navigation';
import Validation from './helper/validation';
import Network from './helper/network';
import SavedGames from './helper/savedGames';
import {dispatch} from "../../store";
import Characters from './helper/steps/characters'
import Actions from './helper/steps/actions'
import GameDesign from './helper/steps/gameDesign'

export const initialState = {
    isLoading: false,
    retrievingData: true,
    error: null,
    gamesList: [],
    product: null,
    sortBy: null,
    letsGoButtonClicked: false,
    actionPrevalenceSpeechBubbleOpened: true,
    validatedStep: true,
    backgroundUrls: [],
    isCustomBackground: false,
    characterSetsList: [
        {
            id: '1',
            name: 'Set 1',
            previewPath: '/character-sets/character-sets/character-set-1/set_1_preview.svg',
            characterSetListPath: '/character-sets/character-sets/character-set-1/varient-1'
        },
        {
            id: '2',
            name: 'Set 2',
            previewPath: '/character-sets/character-sets/character-set-2/set_2_preview.svg',
            characterSetListPath: '/character-sets/character-sets/character-set-2/varient-1'
        },
        {
            id: '3',
            name: 'Set 3',
            previewPath: '/character-sets/character-sets/character-set-3/set_3_preview.svg',
            characterSetListPath: '/character-sets/character-sets/character-set-3/varient-1'
        },
        {
            id: '4',
            name: 'Set 4',
            previewPath: '/character-sets/live-character-sets/live-set-preview.jpg',
            characterSetListPath: '/character-sets/live-character-sets/live-character-set-1' 
        },
    ],
    colourThemes: [
        {
            id: 'a',
            name: 'Colour theme 1',
            themeSetPath: '/colour-themes/theme-1.svg',
            tintV1: '#050833',
            tintV2: '#54086B',
            tintV3: '#311E4B',
            button: '#00BEC5',
            timer: '#205DA7',
            credits: '#EB5B21',
            boardStartTile: '#80BE68',
            boardEndTile: '#E24C4B',
            boardCharacterTile: '#53B0B5',
            boardEndTileColour: '#FFFFFF',
            cardPoolResultsSheet: '#00AF9A',
            InnovationCardsBackground: '#3B897C',
            discard: '#DB455E',
            shortlist: '#A6B72E',
            backgroundsPath: '/backgrounds/theme-1',
            actionCardsPath: '/actions/theme-1/',
            actionCardStylesPath: [
                '/actions/theme-1/default-1/Actioncard_A1_v1a.svg',
                '/actions/theme-1/role-1/Actioncard_A2_v1a.svg',
                '/actions/theme-1/wildcard-1/Actioncard_A3_v1a.svg',
                '/actions/theme-1/default-2/Actioncard_B1_v1a.svg',
                '/actions/theme-1/role-2/Actioncard_B2_v1a.svg',
                '/actions/theme-1/wildcard-2/Actioncard_B3_v1a.svg',
            ],
        },
        {
            id: 'b',
            name: 'Colour theme 2',
            themeSetPath: '/colour-themes/theme-2.svg',
            tintV1: '#487492',
            tintV2: '#00BEC5',
            tintV3: '#58B0B6',
            button: '#278E96',
            timer: '#E24C4B',
            credits: '#2E313D',
            boardStartTile: '#80BE68',
            boardEndTile: '#E24C4B',
            boardCharacterTile: '#83256C',
            boardEndTileColour: '#E24C4B',
            cardPoolResultsSheet: '#B3DDDD',
            InnovationCardsBackground: '#84A493',
            discard: '#DB455E',
            shortlist: '#A6B72E',
            backgroundsPath: '/backgrounds/theme-2',
            actionCardsPath: '/actions/theme-2/',
            actionCardStylesPath: [
                '/actions/theme-2/default-1/Actioncard_A1_v2a.svg',
                '/actions/theme-2/role-1/Actioncard_A2_v2a.svg',
                '/actions/theme-2/wildcard-1/Actioncard_A3_v2a.svg',
                '/actions/theme-2/default-2/Actioncard_B1_v2a.svg',
                '/actions/theme-2/role-2/Actioncard_B2_v2a.svg',
                '/actions/theme-2/wildcard-2/Actioncard_B3_v2a.svg',
            ],
        },
        {
            id: 'c',
            name: 'Colour theme 3',
            themeSetPath: '/colour-themes/theme-3.svg',
            tintV1: '#FFC5D0',
            tintV2: '#F52EC0',
            tintV3: '#FF7628',
            button: '#528A97',
            timer: '#FF7628',
            credits: '#B7067C',
            boardStartTile: '#80BE68',
            boardEndTile: '#E24C4B',
            boardCharacterTile: '#DD77E8',
            boardEndTileColour: '#E24C4B',
            cardPoolResultsSheet: '#93DFD7',
            InnovationCardsBackground: '#22CAE0',
            discard: '#DF9393',
            shortlist: '#93DF97',
            backgroundsPath: '/backgrounds/theme-3',
            actionCardsPath: '/actions/theme-3/',
            actionCardStylesPath: [
                '/actions/theme-3/default-1/Actioncard_A1_v3a.svg',
                '/actions/theme-3/role-1/Actioncard_A2_v3a.svg',
                '/actions/theme-3/wildcard-1/Actioncard_A3_v3a.svg',
                '/actions/theme-3/default-2/Actioncard_B1_v3a.svg',
                '/actions/theme-3/role-2/Actioncard_B2_v3a.svg',
                '/actions/theme-3/wildcard-2/Actioncard_B3_v3a.svg',
            ],
        },
        {
            id: 'd',
            name: 'Colour theme 4',
            themeSetPath: '/colour-themes/theme-4-stick-theory.svg',
            tintV1: '#52c6d8',
            tintV2: '#fe00d7',
            tintV3: '#FF7628',
            button: '#fe00d7',
            timer: '#fe00d7',
            credits: '#020a0a',
            boardStartTile: '#afd225',
            boardEndTile: '#e52e12',
            boardCharacterTile: '#fe00d7',
            boardEndTileColour: '#e52e12',
            cardPoolResultsSheet: '#bdf0f7',
            InnovationCardsBackground: '#bdf0f7',
            discard: '#e52e12',
            shortlist: '#afd225',
            backgroundsPath: '/backgrounds/theme-3',
            actionCardsPath: '/actions/theme-4/',
            actionCardStylesPath: [
                '/actions/theme-4/default-1/StickTheory_Card_01.svg',
                '/actions/theme-4/role-1/StickTheory_Card_01_Role.svg',
                '/actions/theme-4/wildcard-1/StickTheory_Card_01_Wildcard.svg',
            ],
        },
        {
            id: 'e',
            name: 'Colour theme 5',
            themeSetPath: '/colour-themes/theme-5-CPI.svg',

            tintV1: '#0058aa',
            tintV2: '#00bebb',
            tintV3: '#0058aa',

            button: '#00BEC5',
            timer: '#e14b49',
            credits: '#0058aa',

            boardStartTile: '#80bd68',
            boardEndTile: '#e14b49',
            boardCharacterTile: '#8435c2',

            cardPoolResultsSheet: '#00AF9A',
            InnovationCardsBackground: '#3B897C',

            discard: '#e14b49',
            shortlist: '#80bd68',

            backgroundsPath: '/backgrounds/theme-3',

            actionCardsPath: '/actions/theme-5/',
            actionCardStylesPath: [
                '/actions/theme-5/default-1/CPI_Card01.svg',
                '/actions/theme-5/role-1/CPI_Card01_Role.svg',
                '/actions/theme-5/wildcard-1/CPI_Card01_Wildcard.svg',
            ],
        }
    ],
    selectedGame: {
        accessToPlay: 'informationOnly',
        archived: false,
        completed: false,
        id: null,
        activeOuterSection: 'welcome',
        activeInnerSection: 0,
        shareDetails: {
            published: 'notPublished',
            accessToPlay: 'informationOnly',
            copy: 'canCopy'
        },
        aboutTheGame: {
            name: '',
            coverImageURL: '',
            toEnsure: '',
            toEnsureOther: '',
            forWho: '',
            forWhoOther: '',
            typicallyAged: [],
            toAchieve: '',
            process: '',
            playerRoleName: '',
            playerRepresentation: '',
            playerExperience: '',
            playerRoleDescription: '',
            winningTheGame: ''
        },
        gameDesign: {
            selectedTheme: {
                id: 'a',
                name: 'Colour theme 1',
                themeSetPath: '/colour-themes/theme-1.svg',
                tintV1: '#050833',
                tintV2: '#54086B',
                tintV3: '#311E4B',
                button: '#00BEC5',
                background: 'rgba(0, 190, 197, 0.4)',
                timer: '#205DA7',
                credits: '#EB5B21',
                boardStartTile: '#80BE68',
                boardEndTile: '#E24C4B',
                boardCharacterTile: '#53B0B5',
                boardEndTileColour: '#FFFFFF',
                cardPoolResultsSheet: '#00AF9A',
                InnovationCardsBackground: '#3B897C',
                discard: '#DB455E',
                shortlist: '#A6B72E',
                backgroundsPath: '/backgrounds/theme-1',
                actionCardsPath: '/actions/theme-1/',
                actionCardStylesPath: [
                    '/actions/theme-1/default-1/Actioncard_A1_v1a.svg',
                    '/actions/theme-1/role-1/Actioncard_A2_v1a.svg',
                    '/actions/theme-1/wildcard-1/Actioncard_A3_v1a.svg',
                    '/actions/theme-1/default-2/Actioncard_B1_v1a.svg',
                    '/actions/theme-1/role-2/Actioncard_B2_v1a.svg',
                    '/actions/theme-1/wildcard-2/Actioncard_B3_v1a.svg',
                ],
            },
            logo: '',
            freeActionTokenUrl: 'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Fdefault-free-action-token.svg?alt=media&token=0b6b6c2e-4e88-4007-9922-7cebbcc68cb2',
            acceptedLogoTerms: false,
            selectedBackground: '',
            selectedCardStyle: 1,
            selectedDefaultActionColour: '',
            selectedRoleActionColour: '',
            selectedWildcardActionColour: '',
            background: '',
            actionCardPaths: {
                defaultCards: [
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1a.svg?alt=media&token=b38ca33d-155b-4b1b-9ee1-de23c6f58a8c',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1b.svg?alt=media&token=657e8149-3d2b-48ed-9938-b32a6e724daf',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1c.svg?alt=media&token=df4ab3b4-9c54-443c-b830-fce8e2ff6702',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1d.svg?alt=media&token=772e0cb8-60f1-45f3-9d32-fd079dc55a6c',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1e.svg?alt=media&token=4738fba6-b0b1-4993-83cf-3cd48499ed7f',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1f.svg?alt=media&token=988360d2-db9e-4a63-a06b-2ffeb1deb33f',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fdefault-2%2FActioncard_B1_v1g.svg?alt=media&token=164bc747-d4f5-4260-b334-0020c84148c6'

                ], roleCards: [
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1a.svg?alt=media&token=d6ecdf12-218c-4838-8d63-eb425c7a721a',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1b.svg?alt=media&token=95506505-7c4e-4dfb-ab78-6579b12e84cc',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1c.svg?alt=media&token=b3b3b1fa-6b1d-42b6-ae08-d2e39253b804',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1d.svg?alt=media&token=58dc65f7-0af6-46ae-b065-16553e881b03',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1e.svg?alt=media&token=0bc057c0-543d-4711-a3bf-e947f58f4776',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1f.svg?alt=media&token=0ba5de01-a695-4306-9c1a-686cdad81a2d',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Frole-2%2FActioncard_B2_v1g.svg?alt=media&token=9b0feddf-9980-4cbd-bb7e-927f7cded273'

                ], wildcardsCards: [
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1a.svg?alt=media&token=bf7c54eb-522a-42de-aa47-44145f4c001f',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1b.svg?alt=media&token=dfe72c16-d18e-4d0d-9bb5-6ed1870e387b',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1c.svg?alt=media&token=69134458-29ba-47be-b9d4-80b55fe779b0',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1d.svg?alt=media&token=564686b5-2983-4114-812c-596188f4d0c0',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1e.svg?alt=media&token=5c2e5d2d-abc3-40ff-9810-c11af4efdd6a',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1f.svg?alt=media&token=9ba12213-6dae-4d4a-8333-4b890fd33f27',
                    'https://firebasestorage.googleapis.com/v0/b/brightgame-80a0e.appspot.com/o/actions%2Ftheme-1%2Fwildcard-2%2FActioncard_B3_v1g.svg?alt=media&token=c8855660-8395-41ec-8d0c-b513cb88f57f'

                ]
            }
        },
        learningObjectives: {
            learningObjectivesList: [],
            learningImpactsList: [],
            keyMessages: {
                first: '',
                second: '',
                third: '',
                central: '',
            },
        },
        actions: {
            freeActionTokenEnabled: true,
            actionTitlesList: [],
            freeActionTokenDescription: '',
            freeActionConstraintsForUse: [],
            iconDesign: '',
            clustersList: [],
            actionsList: [

            ]
        },
        learningFocus: {
            roles: []
        },
        learnerRetainment: {
            keyFacts: [],
            otherKeyFacts: []
        },
        scenarios: {
            firstVisit: true,
            scenarios: [
                //     {
                //     id: 's1', // string
                //     title: 'Scenario Title', // string
                //     description: 'A description of the scenario', // list of string
                //     keyLearnings: ['learning 1', 'learning 2', 'learning 3'], // list of string
                //     situationalStory: '', // list of string (urls)
                //     images: []
                // }
            ],
            keyFacts: [],
        },
        //YOUR PROCESS
        processStages: {
            realWorldProcess: '',
            principleType: '',
            keywords: [],
            similarGames: [],
            minimumReached: false,
            warningAcknowledged: false,
            stages: [
                {
                    id: 's1',
                    title: '',
                    summary: '',
                    // colour: '',
                    // complete: false,
                    roles: [],
                    // additionalRoles: [],
                    // mustList: [],
                    // shouldList: [],
                    // couldList: [],
                    // neverDoList: [],
                },
            ],
            selectedField: ''
        },
        characters: {
            roleNamesList: [
                // 'Engineer',
                // 'Operating Manager',
                // 'CEO',
                // 'Welder'
            ],
            // additionalRolesNameList: [],
            rolesList: [],
            characterSet: {
                id: '1',
                name: 'Set 1',
                previewPath: '/character-sets/character-sets/character-set-1/set_1_preview.svg',
                characterSetListPath: '/character-sets/character-sets/character-set-1/varient-1'
            }
        },
        results: {
            winningTheGame: ''
        },
    },
};

const aboutTheGame = new AboutTheGame();
const learningObjectives = new LearningObjectives();
const learningFocus = new LearningFocus();
const learningRetainment = new LearningRetainment();
const scenarios = new Scenarios();
const network = new Network();
const validation = new Validation();
const navigation = new Navigation();
const savedGames = new SavedGames();
const processStages = new ProcessStages();
const rolesInvolved = new RolesInvolved();
const characters = new Characters();
const actions = new Actions();

const gameDesign = new GameDesign();

const slice = createSlice({
    name: 'games',
    initialState,
    reducers: {

        setBackgroundUrls(state, action) {
            state.backgroundUrls = action.payload;
        },

        // VALIDATION
        hasError: validation.hasError,
        changeStepValidation: validation.changeStepValidation,
        completeGame: validation.completeGame,

        // SAVED GAMES
        onSuccessfulGameDelete: savedGames.onSuccessfulGameDelete,

        // NAVIGATION
        onBackStep: navigation.onBackStep,
        onNextStep: navigation.onNextStep,
        onGotoStep: navigation.onGotoStep,
        setSelectedGame: navigation.setSelectedGame,
        setCanEditSelectedGame: navigation.setCanEditSelectedGame,

        // NETWORK
        startLoading: network.startLoading,
        startDataRetrieval: network.startDataRetrieval,
        stopLoading: network.stopLoading,
        stopDataRetrieval: network.stopDataRetrieval,
        onUpdateGameData: network.onUpdateGameData,

        // NEW GAME (STEP 0)
        changeAboutGameKeyValue: aboutTheGame.changeAboutGameKeyValue,
        setGameCoverImage: aboutTheGame.setGameCoverImage,
        setGameId: aboutTheGame.setGameId,
        resetGameState: aboutTheGame.resetGameState,

        // Game Design (STEP 1)
        selectColourPalette: gameDesign.selectColourPalette,
        selectBackground: gameDesign.selectBackground,
        selectActionCardStyle: gameDesign.selectActionCardStyle,
        updateGameDesignKeyValue: gameDesign.updateGameDesignKeyValue,
        toggleAcceptedLogoTerms: gameDesign.toggleAcceptedLogoTerms,
        uploadLogo: gameDesign.uploadLogo,
        uploadBackground: gameDesign.uploadBackground,

        // LEARNING OBJECTIVES (STEP 2)
        addLearningObjective: learningObjectives.addLearningObjective,
        updateLearningImpact: learningObjectives.updateLearningImpact,
        updateLearningObjective: learningObjectives.updateLearningObjective,
        addLearningImpact: learningObjectives.addLearningImpact,
        deleteLearningObjective: learningObjectives.deleteLearningObjective,
        changeKeyMessage: learningObjectives.changeKeyMessage,

        // LEARNING FOCUS (STEP 2)
        addRole: learningFocus.addRole,
        deleteRole: learningFocus.deleteRole,
        changeRoleName: learningFocus.changeRoleName,
        updateRoleTasks: learningFocus.updateRoleTasks,

        // LEARNING RETAINMENT (STEP 3A)
        changeKeyFactName: learningRetainment.changeKeyFactName,
        addKeyFact: learningRetainment.addKeyFact,
        deleteKeyFact: learningRetainment.deleteKeyFact,
        updateOtherKeyFacts: learningRetainment.updateOtherKeyFacts,

        // SCENARIOS
        addScenario: scenarios.addScenario,
        updateScenario: scenarios.updateScenario,
        addScenariosKeyFact: scenarios.addKeyFact,
        updateScenariosKeyFact: scenarios.updateKeyFact,
        deleteScenariosKeyFact: scenarios.deleteKeyFact,
        setFirstVisit: scenarios.setFirstVisit,
        deleteScenario: scenarios.deleteScenario,

        // PROCESS STAGES
        changeProcessStagesKeyValue: processStages.changeProcessStagesKeyValue,
        addKeyword: processStages.addKeyword,
        deleteKeyword: processStages.deleteKeyword,
        addSimilarGame: processStages.addKeyword,
        deleteSimilarGame: processStages.deleteKeyword,
        acknowledgeWarning: processStages.acknowledgeWarning,

        addStage: processStages.addStage,
        deleteStage: processStages.deleteStage,
        updateStageKeyValue: processStages.updateStageKeyValue,
        updateStagesActionTitles: processStages.updateStagesActionTitles,
        deleteStagesActionTitles: processStages.deleteStagesActionTitles,
        addStageActionTitles: processStages.addStageActionTitles,

        // ROLES INVOLVED
        addInvolvedRole: rolesInvolved.addInvolvedRole,
        changeInvolvedRoleName: rolesInvolved.changeInvolvedRoleName,
        deleteInvolvedRole: rolesInvolved.deleteInvolvedRole,
        updateInvolvedRoleStages: rolesInvolved.updateInvolvedRoleStages,
        updateInvolvedRolePrefilled: rolesInvolved.updateInvolvedRolePrefilled,

        //CHARACTERS
        // addRoleToRolesList: characters.addRoleToRolesList,
        deleteRoleFromRolesList: characters.deleteRoleFromRolesList,
        updateRole: characters.updateRole,
        clearRoles: characters.clearRoles,
        changeCharactersKeyValue: characters.changeCharactersKeyValue,
        // updateCharacterRoleKeyValue: characters.updateCharacterRoleKeyValue,
        // updateAdditionalCharacterRoleKeyValue: characters.updateAdditionalCharacterRoleKeyValue,
        // addActor: characters.addActor,
        updateRoles: characters.updateRoles,
        // updateAdditionalRoles: characters.updateAdditionalRoles,
        // addAdditionalActor: characters.addAdditionalActor,
        // deleteActor: characters.deleteActor,
        // deleteAdditionalActor: characters.deleteAdditionalActor,

        // Actions
        clickLetsGoButton: actions.clickLetsGoButton,
        switchActionPrevalenceSpeechBubble: actions.switchActionPrevalenceSpeechBubble,
        addWildcard: actions.addWildcard,
        deleteWildcard: actions.deleteWildcard,
        updateWildcardValue: actions.updateWildcardValue,
        createClustersList: actions.createClustersList,
        updateClusterValue: actions.updateClusterValue,
        updateActionTitle: actions.updateActionTitle,
        deleteAction: actions.deleteAction,
        updateActionWildcardStatus: actions.updateActionWildcardStatus,
        updateClustersActionIds: actions.updateClustersActionIds,
        updateActionKeyValue: actions.updateActionKeyValue,
        updateActionDetails: actions.updateActionDetails,
        updateActionPrevalence: actions.updateActionPrevalence,
        deletePrevalenceFromCard: actions.deletePrevalenceFromCard,
        addPrevalenceToCard: actions.addPrevalenceToCard,
        reduceActionPrevalence: actions.reduceActionPrevalence,
        addActionTitle: actions.addActionTitle,
        updateActionInformalOrder: actions.updateActionInformalOrder,
        updateFreeActionDescription: actions.updateFreeActionDescription,
        updateFreeActionConstraints: actions.updateFreeActionConstraints

    },
});


// Reducer
export default slice.reducer;

export function setUserGameData(data) {
    return async () => {
        dispatch(slice.actions.startDataRetrieval());
        try {
            dispatch(slice.actions.stopDataRetrieval(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function onCreateGameData(createGameData, data) {
    return async () => {
        try {
            // console.log("DATA!")
            // console.log(data)
            const gameId = await createGameData(data);
            dispatch(slice.actions.setGameId(gameId));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function onGameDelete(gameId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.onSuccessfulGameDelete(gameId));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function retrieveBackgroundUrls(getImageUrlsFromStorage, backgroundsPath) {
    return async () => {
        try {
            const urls = await getImageUrlsFromStorage(backgroundsPath);
            dispatch(slice.actions.setBackgroundUrls(urls));
            //dispatch(slice.actions.selectBackground({url: urls[0]}))
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// Actions
export const {
    completeGame,
    onGotoStep,
    onBackStep,
    onNextStep,
    setSelectedGame,
    setCanEditSelectedGame,
    addLearningObjective,
    updateLearningImpact,
    updateLearningObjective,
    addLearningImpact,
    deleteLearningObjective,
    addRole,
    deleteRole,
    changeRoleName,
    updateRoleTasks,
    changeCharactersKeyValue,
    updateRole,
    updateCharacterRoleKeyValue,
    updateAdditionalCharacterRoleKeyValue,
    changeProcessStagesKeyValue,
    addKeyword,
    // addRoleToRolesList,
    deleteRoleFromRolesList,
    deleteKeyword,
    updateStageKeyValue,
    addStage,
    deleteStage,
    setGameId,
    onUpdateGameData,
    updateGameDesignKeyValue,
    resetGameState,
    setGameCoverImage,
    onSuccessfulGameDelete,
    changeKeyFactName,
    addKeyFact,
    deleteKeyFact,
    updateOtherKeyFacts,
    changeKeyMessage,
    addScenario,
    updateScenario,
    changeAboutGameKeyValue,
    selectColourPalette,
    selectBackground,
    selectActionCardStyle,
    toggleAcceptedLogoTerms,
    addScenariosKeyFact,
    setFirstVisit,
    deleteScenario,
    deleteScenariosKeyFact,
    updateScenariosKeyFact,
    clickLetsGoButton,
    switchActionPrevalenceSpeechBubble,
    updateRoles,
    clearRoles,
    uploadLogo,
    uploadBackground,
    updateStagesActionTitles,
    deleteStagesActionTitles,
    addStageActionTitles,
    changeStepValidation,
    addWildcard,
    deleteWildcard,
    updateWildcardValue,
    createClustersList,
    updateClusterValue,
    updateClustersActionIds,
    updateActionKeyValue,
    changeStageTitle,
    updateStageDescription,
    updateActionPrevalence,
    // updateAdditionalRoles,
    deletePrevalenceFromCard,
    addPrevalenceToCard,
    updateActionDetails,
    reduceActionPrevalence,
    acknowledgeWarning,
    updateActionTitle,
    deleteAction,
    addActionTitle,
    updateActionInformalOrder,
    updateActionWildcardStatus,
    updateFreeActionDescription,
    updateFreeActionConstraints
} = slice.actions;
