import {useState} from 'react';
// @mui
import {Grid, Card, Typography, IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';

// ----------------------------------------------------------------------

export default function BG_MessageDialog(props) {
//   const { enqueueSnackbar } = useSnackbar();
//   const [state, setState] = useState({
//     value,
//     copied: false,
//   });


  return (
        <Grid style={{display: "flex", height: "100vh"}}>
            <Card style={{margin: "auto", width: "40vw"}}>
                <Typography variant="h3" sx={{padding: '40px', paddingBottom: '15px', textAlign: 'left', fontWeight: 'bold'}}>
                    {props.title}
                </Typography>
                <Typography variant="p" sx={{padding: '40px', paddingTop: '0px', textAlign: 'left', display: "block"}}>
                    {props.message}
                </Typography>
            </Card>
        </Grid>
  );
}