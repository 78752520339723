import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

let errorTimer;
let situationalTimer;

const initialState = {
    isLoading: false,
    errorChatBot: {
        isError: false,
        errorTitle: null,
        errorText: null,
        errorAnimation: 'shocked'
    },
    situationalChatBot: {
        isSituationalActive: false,
        situationTitle: null,
        situationText: null,
        situationAnimation: 'smallEyes',
    },
    chatBotData: {
        welcome: {
            'Welcome to our game creator': ['Watch our video below...'],
        },
        'about the game': {
            'Game title': ['Let’s think of a name for your game...'],
            'About my game': [''],
        },
        'game design': {
            'Discover your options': [''],
            'Colour theme': ['Select a colour palette from the options below to apply to your board.'],
            'Your logo': [''],
            'Your background': [''],
            'Your card style': ['Focus on the style you prefer, we will add colour next!'],
            Preview: [''],
        },
        scenarios: {
            'Scenario Creator': [
                'Create at least 3 scenarios for players to choose from.',
                'Creating 4-5 scenarios makes for good gameplay and a stickier game. Players are more likely to play your game more than once.',
                'Select different scenarios that need different actions and approaches to your process - We will create these different actions later on.',
                'Could any of the actions you have created relate to specific scenarios.',
                'Think about what you should do differently, and types of mistakes people make in the specific scenarios you are creating.',
            ],
            'Key Facts': ['Key facts are important pieces of information that your players should know', "Too many key facts will saturate your gameCreator intended learning outcome! So stick to a maximum of 10.", "Press the Enter key to add a key fact!"],
        },
        'learning objectives': {
            'Learning objectives': ['What are the learning outcome of your game?'],
            'Key messages': ['Below, create a few key messages that you want learners to \'take away\' from playing your game.  Use the three circles blow to capture three key messages. Then consider one overall message that you would like learners to recall from playing.']
            },
        'your process': {
            'Real world process': [],
            Stages: [
                'Five stages is ideal for gameplay – almost all processes can be simplified into 5  stages.',
                'When creating stages, think about milestones in your process, where you review progress, costs or efforts.',
                'Don’t forget to add a stage for what you do before you start your process. Whether  planting a tree or building a skyscraper there is always pre-planning and preparation! - Thinking about what happens after you have finished the activity is useful. Do you review, share or pass on items or information? Are there any review activities in your process?',
                'Is the process you are creating in your game published anywhere? If so, include the link in your game introduction for reference! ',
            ],
        },
        // scenarios: {steps: {}},
        characters: {
            'Key roles': [
                'You should create a minimum of 5 roles associated directly or indirectly with a process.  Ideally over 10 so roles are a constraint to the players when selecting actions',
                'Although not essential, ideally a game will have 15 or more roles associated with the  action card pack for best gameplay.',
            ],
            'Additional roles': [
                'As well as those essential to the role, think about those that might delay or misdirect  results….such as asking friends to give you business advice for example.',
                'As well as those actually typically involved in the process you can associate roles that are  not typically involved or even those that might harm the process in some way!',
                'You can create up to 20 roles and associate these with character images of your choice.',
            ],
            'Select your character set': [],
            'Matching characters': ['To assign a job role, simply click on a character and select the role they should represent. You can link to a real world individual by clicking on a character. Be careful to avoid stereotypes!'],
        },
        actions: {
            'Action Titles': [
                'It’s important to have a good spread of actions across your stages for gameplay.',
                'Try to make sure you have around 8-12 actions cards per stage.',
                'It’s important to have a good range of types of actions for gameplay.',
                'Start by thinking about the obvious actions you should always do regardless of the  situation or scenario.',
                'Then think about the actions that cause problems and should be avoided at all costs! These are Never-do actions (smiley emoji)',
            ],
            'Action Ordering': ["For each stage, place the actions in their logical ordering", "They is known as the actions informal ordering, and is essential for creating a journey for the player."],
            'Action Details': ["The ‘cost’ of an action relates to the relative time and resources required to implement it. Select from the effort types (A-F) for each action for automatic costing of action cards", "Choose A or B where your action card relates to a small task", "Choose E and F for where your action card relates to a major task", "Try not to create too many major tasks as this will use the players budget up fast! - You can only create so many high value items as the available selectable is capped", "If you have created a lot of actions you will have to use smaller action card costs to  spread your budget."],
            'Action Prevalence': ['Some actions may have to logically occur before other actions can take place', "Action prevalence's are how we enforce this. Using the dropdown on the left, select each action that must occur after one or more actions have taken place and add the required actions."],
            'Wildcards': [
                'Wild cards are really bad or really good actions in their own right. The cards look slightly different to make players think twice about using them.',
                'Wildcards are a great way of reinforcing practical learning.',
                'You can use wildcards to trick players into making mistakes... make your bad wildcards sound like plausible and good selections!',
                'You can play down good wildcards so they don’t sound very useful!',
                'Have fun making your wildcards!'
            ],
            'Clusters': ["Think of clusters as related actions that have a really good or bad effect.", "Sometimes these are cumulative effects – e.g. on their own they have little significance but together they create a positive impact…or an issue!", "Are there any groups of actions you think are likely to have an aggregate effect if placed  on the board together?"],
            'Free action token': [
                'Think of a free action as good fortune.',
                'Good luck can arise from a range of sources, for example free business advise or unprompted help from a colleague!',
                'Think about who or what could intervene in your process to enable free support and  assistance.',
                'Add any constraints to your action (any specific stage, or actions the token can or can’t be used with)',
                'Why not connect your free token to the real world – is there someone or and organisation  that would likely be involved. Add their details'

            ],
        },
        results: {
            'Action costs & impacts': ['Please describe the implication of using each card. This will be expressed\n' +
            '                        in the final game report to the player. Consider both the impact and the reason for the impact\n' +
            '                        in your summary']
        },
        complete: {steps: {}},
    },
};

const slice = createSlice({
    name: 'chatbotManager',
    initialState,
    reducers: {
        hasError(state, action) {
            const {errorTitle, errorText} = action.payload;
            const newErrorChatBot = {...state.errorChatBot}
            newErrorChatBot.isError = true;
            newErrorChatBot.errorTitle = errorTitle;
            newErrorChatBot.errorText = errorText;
            newErrorChatBot.errorAnimation = 'shocked';
            state.errorChatBot = newErrorChatBot;

            clearTimeout(errorTimer);
            // Play error animation for 5 seconds
            errorTimer = setTimeout(() => {
                dispatch(clearError())
                dispatch(changeErrorAnimation({animation: 'idle'}))
            }, 5000);
        },

        clearError(state, action) {
            const newErrorChatBot = {...state.errorChatBot}
            newErrorChatBot.isError = false;
            newErrorChatBot.errorTitle = null;
            newErrorChatBot.errorText = null;
            state.errorChatBot = newErrorChatBot;
            clearTimeout(errorTimer);
        },

        changeErrorAnimation(state, action) {
            const {animation} = action.payload;
            state.errorChatBot.errorAnimation = animation;
        },

        //updates state when there is interaction
        setInteractivity(state, action) {
            const {isInteractive} = action.payload;
            state.situationalChatBot.isInteractive = isInteractive;
        },

        setSituationNotification(state, action) {
            const {situationTitle, situationText} = action.payload;
            const newSituationalChatBot = {...state.situationalChatBot}

            newSituationalChatBot.isSituationalActive = true;
            newSituationalChatBot.situationTitle = situationTitle;
            newSituationalChatBot.situationText = situationText;
            newSituationalChatBot.situationAnimation = 'smallEyes';
            state.situationalChatBot = newSituationalChatBot;

            clearTimeout(situationalTimer);
            situationalTimer = setTimeout(() => dispatch(clearSituationNotification({})), 5000);
        },

        clearSituationNotification(state, action) {

            const newSituationalChatBot = {...state.situationalChatBot}
            newSituationalChatBot.isSituationalActive = false;
            newSituationalChatBot.situationTitle = null;
            newSituationalChatBot.situationText = null;
            state.situationalChatBot = newSituationalChatBot;
            clearTimeout(situationalTimer);
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// export function setLoading(value) {
//     return async () => {
//         dispatch(slice.actions.updateLoading(value));
//     };
// }

export const {
    hasError,
    clearError,
    setSituationNotification,
    clearSituationNotification,
    setInteractivity,
    changeErrorAnimation
} = slice.actions;
